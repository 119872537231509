@import "../../../scss/_colors";

.newMessageNotification {
  position: relative;
  width: 100%;
  height: 20px;
  text-align: center;
  background: $clr__red;
  color: $clr__white;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 1.5rem;
}

.overlay_box-auto {
  position: relative;
  top: 30vh;
  margin: 0 auto;
  width: 60vw;
  height: auto;
  background: $clr__white;
  color: $clr__black;
  padding: 2vh 5vw;
  border-radius: 10px;
  .overlay_message-auto {
    position: relative;
    font-size: 2rem;
    font-weight: 800;
    text-transform: capitalize;
  }
  .overlay_button_ok-auto {
    position: relative;
    width: 100%;
    left: 0;
    bottom: 0;
    margin-top: 10px;

    button {
        width: 100%;
        height: 50px;
        background-color: $clr__green;
        color:$clr__white;
        border: none;
        border-radius: 10px;
        font-weight: 800;
        font-size: 1.3rem;
    }
  }
}
