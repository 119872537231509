@import "../../../scss/_colors";
@import "../../../scss/_mixins";

.sidenav {
  max-width: 350px;
  min-width: 320px;
  &__title {
    font-size: 24px;
    font-weight: 700;
    padding: 10px 20px 5px 20px;
    @include display-flex();
    @include justify-content(space-between);
    @include align-items(center);
  }
  &__close {
    width: 50px;
    height: 50px;
    top: 0;
    right: 0;
    position: absolute;
    color: $color-body;
    border: unset;
    background: unset;
    @include display-flex();
    @include justify-content(center);
    @include align-items(center);
    &:focus,
    &:active {
      background: unset;
    }
  }
  &__subtitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    color: $color-grey-900;
    text-transform: capitalize;
    padding: 20px 20px 10px 20px;
    @media all and (max-width: 375px) {
      padding: 20px 20px 5px 20px;
    }
  }
  li {
    line-height: normal;
    height: auto;
    a {
      width: 100%;
      border-bottom: 1px solid #EEE;
      padding: 14px 16px;
      line-height: normal;
      height: auto;
      @include display-flex();
      @include align-items(center);
    }
  }
  &__link {
    height: 45px;
    margin-top: 0;
    @include display-flex();
    @include align-items(center);
    button {
      width: 100%;
      background: unset;
      border: unset;
      padding: 14px 16px;
      @include display-flex();
      @include align-items(center);
    }
  }
  .span {
    &--icon {
      width: 40px;
      @include display-flex();
      @include align-items(center);
      &.icon {
        &--floor-evac {
          svg {
            position: relative;
            left: -4px;
          }
        }
      }
    }
    &--png {
      width: 40px;
      @include display-flex();
      @include align-items(center);
      img {
        width: 40px;
        @include align-self(center);
      }
    }
    &--text {
      width: calc(100% - 50px);
      padding-left: 15px;
      text-transform: capitalize;
      text-align: left;
      font-size: 18px;
    }
  }
  &-trigger {
    color: #fff;
    background: none;
    border: none;
    width: 10%;
    height: 100%;
    cursor: pointer;
    &:active {
      background-color: transparent;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__overlay--noclose {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    height: 120vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 996;
    display: block;
  }
}