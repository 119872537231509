.layout {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 17px;

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    max-width: 428px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
    margin-bottom: 15px;
  }

  &__header-text {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #545454;
  }

  @media screen and (max-width: 385px) {
    &__header {
      img {
        height: 40px;
      }
    }

    &__header-text {
      font-size: 16px;
    }
  }
}
