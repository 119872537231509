@import '../../../../../scss/_mixins';

.messagebox__templates {
  position: relative;
  width: 100%;
  height: auto;
  max-height: 250px;
  overflow: scroll;
  background-color: #eeeeee;
  // border-top: 1px solid #7e7e7e;
  // -webkit-box-shadow: 0px -5px 23px 0px rgba(0, 0, 0, 0.4);
  // -moz-box-shadow: 0px -5px 23px 0px rgba(0, 0, 0, 0.4);
  // box-shadow: 0px -5px 23px 0px rgba(0, 0, 0, 0.4);
  // border-top: 1px solid #7e7e7e;
  border-top: 1px solid #d8d8d8;
}

.messagebox__templateItem {
  position: relative;
  width: 90%;
  min-height: 40px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  left: 5%;
  font-size: 1.1rem;
  border-top: 1px solid #7e7e7e;
  border-top: 1px solid #d8d8d8;
  .box {
    width: 100%;
    @include display-flex();
    &-icon {
      position: relative;
      top: 1px;
      padding-right: 8px;
    }
  }
}

.messagebox__templateItem:first-child {
  border-top: 0;
}