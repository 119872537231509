@import "__colors.scss";

@font-face {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI"),
    url("/public/font/segoe-ui/SEGOE-UI.woff") format("woff");
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  overflow: auto !important;
}

.App {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hidden {
  display: none !important;
}

/**
 * Overriding default css for filled-in checkboxes for materialize css
 */
.filled-in + span::after {
  border-color: $clr__green !important;
  background-color: $clr__green !important;
}

.filled-in:not(:checked) + span:not(.level):after {
  border-color: $clr__green !important;
  background-color: #fff !important;
}
