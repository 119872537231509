.input-field {
  margin: 0px;
}

.outlined input,
.outlined input[type="text"],
.outlined input[type="password"] {
  background-color: #f3f3f3;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  box-sizing: border-box;
  margin: 0px;
}

.outlined label {
  position: absolute;
  top: -10px;
  left: 12px;
  background: white;
  padding: 0 4px;
  font-size: 12px;
  color: #9e9e9e;
}

.outlined input:focus,
.outlined input[type="text"]:focus,
.outlined input[type="password"]:focus {
  border-bottom: none !important;
  box-shadow: none !important;
}
