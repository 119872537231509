.phone-number-input {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &__container {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }

  &__select {
    position: relative;
    background-color: #f3f3f3;
    border: none;
    border-radius: 4px;
    padding: 9px 30px 9px 12px;
    box-sizing: border-box;
    margin: 0px;
  }

  &__selected-option {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 10px;
    cursor: pointer;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
  }

  &__dropdown_options {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    list-style-type: none;
    border-radius: 8px;
    background-color: #f3f3f3;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    z-index: 1000;
  }

  &__dropdown_option {
    padding: 9px 12px;
    cursor: pointer;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    border-radius: 8;
  }

  &__icon-btn {
    position: absolute;
    top: 2px;
    right: 5px;
  }

  &__hint {
    font-size: 11px;
  }
}

@media screen and (max-width: 430px) {
  .phone-number-input {
    &__select {
      position: relative;
      padding: 9px 20px 9px 6px;
    }

    &__selected-option {
      font-size: 14px;
      column-gap: 5px;
      & > img {
        width: 20px;
        height: 20px;
      }
    }

    &__dropdown_option {
      font-size: 14px;
      & > img {
        width: 20px;
        height: 20px;
      }
    }

    &__icon-btn {
      right: 0px !important;
    }
  }
}

.input-field {
  margin: 0px;
  position: relative;

  button {
    border: none;
    background-color: transparent;
    position: absolute;
    right: 10px;
    margin: 8px 0px;
  }
}

.outlined input,
.outlined input[type="text"],
.outlined input[type="password"] {
  background-color: #f3f3f3;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  box-sizing: border-box;
  margin: 0px;
}

.outlined label {
  position: absolute;
  top: -10px;
  left: 12px;
  background: white;
  padding: 0 4px;
  font-size: 12px;
  color: #9e9e9e;
}

.outlined input:focus,
.outlined input[type="text"]:focus,
.outlined input[type="password"]:focus {
  border-bottom: none !important;
  box-shadow: none !important;
}
