@import "../../../../scss/_colors";

.aw__evacueeStatusSummary {
  margin-bottom: 1.2rem;
  overflow: hidden;
  background: #e5e5e5;
  border-bottom: 1px solid black;
}

.aw__evacueeStatusSummary > .col {
  padding: 0;
  text-align: center;
  height: auto;
  min-height: 25px;
  font-size: 3.5vw;
  text-transform: capitalize;
  font-weight: 800;
}

.aw__evacueeStatusSummary > .col > div:first-child{
  padding: 2px 0;
}

.aw__evacueeStatusSummary > .col > div:last-child{
  line-height: 4.5vh;
}

.aw__evacueeStatusSummary--summary > div:first-child {
  color: #000;
  background-color: #fff;
  padding: 0 !important;
}

.aw__evacueeStatusSummary--summary > div:last-child {
  // padding: 1.5vh 1.5vw;
}


.aw__evacueeStatusSummary--safe > div:first-child {
  background-color: $clr__green;
  color: #fff;
}

.aw__evacueeStatusSummary--unknown > div:first-child {
  background-color: $clr__yellow;
  color: #000;
}

.aw__evacueeStatusSummary--danger > div:first-child {
  background-color: $clr__red;
  color: #fff;
}

.aw__evacueeStatusSummary--total > div:first-child {
  background-color: #000;
  color: #fff;
}

.aw__evacueeStatusSummary--total > div:last-child {
  font-weight: 800;
}


$summary_height : 2vh;
.aw__evacueeStatusSummary__summary {
  position: relative;
  width: 100%;
  height: $summary_height;
  clear: both;
  overflow: hidden;
}

.aw__evacueeStatusSummary__summary--safe {
  min-height: $summary_height;
  background-color: $clr__green;
  float: left;
}

.aw__evacueeStatusSummary__summary--unknown {
  min-height: $summary_height;
  background-color: $clr__yellow;
  float: left;
}

.aw__evacueeStatusSummary__summary--danger {
  min-height: $summary_height;
  background-color: $clr__red;
  float: left;
}

.aw__evacueeStatusSummary__summary--custom {
  min-height: $summary_height;
  background-color: $clr__black;
  float: left;
}

@media screen and (max-width: 320px) {
  .aw__evacueeStatusSummary > .col {
    font-size: 3vw;
  }
}