@import '../../../../scss/__colors', '../../../../scss/_skeleton';

.floor__status {
  position: relative;
  width: 100%;
  height: 100%;
}

.floor__status > .row {
  margin: 10px 0px;
}

.floor__status > .row:first-child {
  margin-top: 20px;
}

.floor__status--label {
  font-size: 1.2rem;
  font-weight: bold;
  display: block;
  position: relative;
  padding-top: 10px;
}

.label--warden {
  padding-top: 0;
}

.floor__status--select,
.floor__status--option {
  font-size: 1.2rem;
  outline: none !important;
  color: #7e7e7e;
}

.floor__status--select > select {
  border: 1px solid #000;
}

.floor__status--event {
  border: 1px solid #000;
  font-size: 1.2rem;
  padding: 10px;
  width: 100%;
}

.floor__status--wardenbox {
  width: 100%;
  height: 45px;
  position: relative;
}

.wardenbox__box {
  position: relative;
  float: left;
  width: 23%;
  height: 100%;
  border: 1px solid #000;
  border-radius: 10px;
  margin-left: 2.66%;
}

.wardenbox__box:first-child {
  margin-left: 0;
}

.wardenbox__box > span {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  text-align: center;
  font-size: 1rem;
  text-transform: capitalize;
}

.wardenbox__box > span:first-child {
  font-size: 1rem;
  font-weight: 800;
}

.wardenbox__box > span:last-child {
  font-weight: bold;
}

.onduty {
  background-color: $clr__green;
  color: #fff;
}

.unknown {
  background-color: $clr__yellow;
  color: #fff;
}

.notavailable {
  background-color: $clr__red;
}

.offduty {
  background-color: $clr__black;
  color: #fff;
}

.total {
  background-color: $clr__black;
  color: #fff;
}

.floorlist {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  margin-top: 10px;
}

.floorlist__head {
  margin-bottom: 0px;
  font-size: 1.2rem;
  font-weight: 800;
  text-transform: capitalize;
  text-align: left;
}

.floorlist__inner {
  background: #eee;
  padding: 10px;
  position: relative !important;
}

.floor {
  margin-bottom: 5px;
}

.floor__name {
  font-size: 1rem;
  border: 1px solid #707070;
  border-radius: 5px;
  text-align: left;
  text-transform: capitalize;
  font-weight: bold;
  height: 40px;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  height: auto;
}

.floor__wardens {
  min-height: 40px;
  max-height: 100%;
  padding-top: 0.5rem !important;
}

.warden__dot {
  width: 10px;
  height: 18px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 0px;
  border-radius: 0px;
  margin-top: 5px;
}

.WardenListPopup {
  position: absolute;
  top: auto;
  left: 0%;
  width: 100%;
  height: auto;
  border: 2px solid black;
  background: white;
  z-index: 999999;
  border-radius: 5px;
}

.WardenListPopup__inner {
  height: 100%;
}

.WardenListPopup__row {
  padding-bottom: 10px;
  margin: 0px;
}

.WardenListPopup__row:first-child {
  padding-top: 10px;
}

.WardenListPopup__row:last-child {
  padding-bottom: 10px;
}

.WardenListPopup__dot {
  height: 30px;
}

.WardenListPopup__name {
  line-height: 200%;
  padding: 0px !important;
  font-size: 1.1rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.WardenListPopup__actions {
  padding: 1% !important;
}

.WardenListPopup__actions > a {
  background: none;
  display: inline-block;
  width: 50%;
  border: none;
  text-align: center;
}

.WardenListPopup__actions > a > i.material-icons {
  font-size: 1.7rem;
}

.WardenListPopup__error {
  line-height: 200%;
  font-weight: bold;
  color: $clr__red;
  font-size: 1.2rem;
}
.WardenListPopup__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background: none;
  width: 100%;
  height: 100%;
}

.floor-active {
  background-color: $clr__blue;
  color: #fafafa;
}
