@import "../../../../../scss/_colors";
@import "../../../../../scss/_mixins";
@import "../../../../../scss/_components";

$evacsafety__menu: "evacsafety__menu";
.#{$evacsafety__menu} {
  width: 100%;
  padding: 0 5px;
  background-color: black;
  @include display-flex();
  @include justify-content(space-between);
  &-btn {
    width: calc(20% - 4px);
    padding: 5px 2px 2px 2px;
    border: 2px solid white;
    max-height: 60px;
    color: white;
    @include border-radius(4px);
    @include display-flex();
    @include flex-wrap(wrap);
    @include justify-content(center);
    &:nth-of-type(1) {
      svg {
        position: relative;
        top: 2px;
      }
    }
    &-icon {
      width: 100%;
      height: 30px;
      text-align: center;
      img {
        width: 20px;
      }
      svg {
        fill: white;
      }
    }
    &-label {
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      font-size: 0.7rem;
      font-weight: 600;
    }
    .text-white {
      color: white;
    }
    &-boba {
      width: 100%;
      position: relative;
      top: -2px;
      @include display-flex();
      @include justify-content(center);
      .boba {
        width: 6px;
        height: 6px;
        margin: 0 1.5px;
        border: 1px solid $color-body;
        @include border-radius(50%);
        &.on--active {
          background-color: $color-body;
          border-color: $color-body;
        }
      }
    }
    &.on--active {
      cursor: pointer;
      background-color: white;
      .#{$evacsafety__menu} {
        &-btn {
          &-label {
            color: $color-body;
          }
          &-icon {
            svg {
              fill: $color-body;
            }
          }
        }
      }
    }
    &--disabled {
      cursor: not-allowed;
      @include opacity(0.5);
    }
  }
}

.evacsafety__displaybar {
  width: 100%;
  padding: 10px 5px 10px 5px;
  background-color: $color-body;
  position: relative;
  @include display-flex();
  @include justify-content(space-between);

  @mixin act-label {
    width: calc(100% - 30px);
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 600;
    padding-right: 5px;
    @media all and (max-width: 370px) {
      font-size: 10px;
    }
  }

  @mixin act-count {
    overflow: hidden;
    font-size: 14px;
    font-weight: 600;
    max-width: 30px;
    min-width: 30px;
    @include border-radius(4px);
    @media all and (max-width: 370px) {
      font-size: 10px;
    }
  }

  .act {
    border-radius: 4px;
    text-align: center;
    @include display-flex();
    @include align-items(center);
    @include justify-content(space-between);
    &:hover {
      cursor: pointer;
    }

    &--safe {
      width: 21%;
      padding: 4px 4px 5px 4px;
      background-color: white;
      .label {
        @include act-label();
        color: $color-label--green;
      }
      .counter {
        @include act-count();
        background-color: $color-label--green;
        color: white;
      }
      &.on--active {
        background-color: $color-label--green;
        .label {
          color: white;
        }
        .counter {
          background-color: white;
          color: $color-label--green;
        }
      }
    }
    &--unknown {
      width: 32%;
      padding: 4px 4px 5px 4px;
      background-color: white;
      margin: 0 2px;
      .label {
        @include act-label();
        color: $color-label--orange;
      }
      .counter {
        @include act-count();
        background-color: $color-label--orange;
        color: white;
      }
      &.on--active {
        background-color: $color-label--orange;
        .label {
          color: white;
        }
        .counter {
          background-color: white;
          color: $color-label--orange;
        }
      }
    }
    &--danger {
      width: 26%;
      background-color: white;
      padding: 4px 4px 5px 4px;
      .label {
        @include act-label();
        color: $color-label--red;
      }
      .counter {
        @include act-count();
        background-color: $color-label--red;
        color: white;
      }
      &.on--active {
        background-color: $color-label--red;
        .label {
          color: white;
        }
        .counter {
          background-color: white;
          color: $color-label--red;
        }
      }
    }
    &--total {
      width: 21%;
      background-color: white;
      padding: 4px 4px 5px 4px;
      margin: 0 2px;
      .label {
        @include act-label();
        color: $color-body;
      }
      .counter {
        @include act-count();
        background-color: $color-body;
        color: white;
      }
      &.on--active {
        background-image: linear-gradient(
          to right,
          #e0eafc 0%,
          #cfdef3 51%,
          #e0eafc 100%
        );
        background-size: 200% auto;
        .label {
          color: $color-body;
        }
        .counter {
          color: $color-body;
          background-color: white;
        }
      }
    }
  }
}

/* Search Component CSS */
.evacsaftey__search {
  position: relative;
  width: 100%;
  height: 75px;
  @include search-field();
}

.evacsafety__search--clear:focus {
  background: transparent;
}

.evacsafety__search--clear:disabled {
  color: #aaa;
}

/**
	* Evacuee List Component CSS
	*/
.evacsaftey__listitem {
  border-bottom: 1px solid #707070;
  padding-bottom: 10px;
  margin-bottom: 10px;
  min-height: 85px;
  position: relative;
  overflow-x: hidden;
}

.evacsaftey__listitem--name {
  font-size: 1.5rem;
  font-weight: 800;
}

.evacsaftey__listitem--phone {
  font-size: 1rem;
}

.evacsaftey__listitem--location {
  font-size: 1rem;
}

.evacsaftey__listitem--status {
  height: 30px;
}

.evacsaftey__listitem--special {
  height: 25px;
}

.evacsaftey__listitem--message {
  height: 25px;
}

.listitem__content {
  position: relative;
  height: auto !important;
  overflow-x: hidden;
  left: 0;
  transition: all 0.3s;
}

.listitem__status {
  width: 100%;
  margin: 0 auto;
  height: 25px;
  position: relative;
  top: 2.5px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 10px;
  line-height: 1.8rem;
  color: white;
}

.listitem__status--safe {
  background: $clr__green;
}

.listitem__status--unknown {
  background: $clr__yellow;
  color: black;
}

.listitem__status--danger {
  background: $clr__red;
}

.listitem__swiperight {
  position: absolute;
  width: 170px;
  min-height: 85px;
  left: -180px;
  transition: all 0.3s;
}

.listitem__swiperight .col {
  padding: 0 !important;
  height: 85px;
  color: white;
}

.listitem__swiperight > div > button {
  border: 0;
  color: white;
  height: 60px;
  width: 90%;
  margin-left: 5%;
  margin-top: 12.5px;
  text-align: center;
  border-radius: 10px;
  text-transform: uppercase;
  line-height: 4.3rem;
  font-weight: 800;
  font-size: 0.9rem;
}

.listitem__swiperight > div > button:disabled {
  background-color: #aaa;
  color: black;
}

.listitem__swiperight--danger {
  background: $clr__red;
}

.listitem__swiperight--safe {
  background: $clr__green;
}

.listitem__swiperight--unknown {
  background: $clr__yellow;
}

.listitem__swipeleft {
  position: absolute;
  width: 170px;
  min-height: 85px;
  transition: all 0.3s;
  right: -180px;
  top: 0;
}

.listitem__swipeleft .col {
  padding: 0 !important;
  height: 85px;
  color: white;
}

.listitem__swipeleft .col a button > i.material-icons {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 4.3rem;
  font-size: 2.5rem;
}

.listitem__swipeleft--call {
  background: $clr__green;
}

.listitem__swipeleft--message {
  background: $clr__msg;
}

.listitem__swipeleft > div button {
  border: 0;
  color: white;
  height: 60px;
  width: 90%;
  margin-left: 5%;
  margin-top: 12.5px;
  text-align: center;
  border-radius: 10px;
  font-weight: 800;
  font-size: 0.9rem;
}

.special__icon {
  display: inline-block;
  width: 30px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.evacsaftey__listitem--special > span:not(:first-child) {
  margin-left: 10px;
}

.special__icon--assist {
  background-image: url("../images/special_assist.png");
}

.special__icon--visitor {
  background-image: url("../images/special_visitor.png");
}

.special__icon--refusal {
  background-image: url("../images/special_refusal.png");
}

.special__icon--unread {
  background-image: url("../images/special_unread2.png");
}

.special__icon--noreply {
  background-image: url("../images/special_noreply.png");
}

.detail__icon {
  position: relative;
  display: inline-block;
  border-radius: 11px;
  padding: 4px;
  padding-left: 0;
  font-size: 0.9rem;
}

.detail__icon > b {
  text-transform: uppercase;
  background-color: $clr__peep;
  color: $clr__white;
  padding: 5px;
  border-radius: 10px;
  font-weight: 400;
}

.detail__icon > b.assist-danger {
  background-color: $clr__red;
  color: $clr__white;
}

.detail__icon > b.assist-safe {
  background-color: $clr__green;
  color: $clr__white;
}

.detail__icon > b.refusal {
  background-color: $clr__red;
  color: $clr__white;
}

.detail__icon:not(:first-child) {
  margin-left: 5px;
}

.detail__icon--assist-safe {
  border: 1px solid $clr__green;
}

.detail__icon--assist-danger {
  // background-color: $clr__peep;
  // color: $clr__white;
  border: 1px solid $clr__red;
}

.detail__icon--refusal {
  // background-color: $clr__red;
  // color: $clr__white;
  border: 1px solid $clr__red;
}

/**
 * SWIPE CLASSES
 */

.swiperight {
  left: 180px;
}

.swipeleft {
  left: -180px;
}

.swipedright {
  left: 0;
}

.swipedleft {
  right: 0;
}

.swipehint--right {
  left: -100px;
}
.swipehint--left {
  right: -100px;
}
.swipehintright--content {
  left: 80px;
}
.swipehintleft--content {
  left: -80px;
}

/**
 * Evacuee Area Options CSS
 */

.evacuee__areaoptions--overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  z-index: 99;
}

.evacuee__areaoptions {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 320px;
  max-width: 90%;
  height: auto;
  background: #fff;
  z-index: 1000;
  @include transform(translate(-50%, -50%));
  &--list {
    padding: 0 15px !important;
  }
  &--childlist {
    padding-left: 15px;
    .floors {
      &-scroll {
        width: 100%;
        max-height: calc(100vh - 300px);
        overflow-y: auto;
      }
      &-list {
        padding-left: 15px;
      }
    }
  }
  &-head {
    width: 100%;
    background-color: $color-body;
    @include display-flex();
    @include justify-content(space-between);
    @include align-items(center);
    h2 {
      color: white;
      font-size: 17px;
      font-weight: 600;
      padding: 0 10px;
    }
    button {
      background-color: unset;
      box-shadow: unset;
      border: unset;
      cursor: pointer;
      padding: 10px;
      @include display-flex();
      @include justify-content(flex-end);
      @include align-items(center);
      &:focus {
        background-color: transparent;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  &--list-title {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
    padding-left: 3px;
  }
  &--childlistitem {
    &-wrapper {
      width: 100%;
      position: relative;
    }
  }
  &--listitem,
  &--childlistitem {
    width: 100%;
    margin-bottom: 8px;
    @include display-flex();
    &.listitem-myarea {
      padding-left: 15px;
      margin-bottom: 20px;
    }
    &.childlistitem--building {
      padding-right: 45px;
    }
    .item {
      &-label {
        width: 100%;
        position: relative;
        color: $color-body;
        padding: 5px 12px;
      }
      &-overlay {
        position: absolute !important;
        width: 100%;
        height: 100% !important;
        top: 0;
        left: 0;
        padding-left: 0;
        z-index: -1;
      }
      &-text {
        font-size: 16px;
        p {
          line-height: normal;
        }
      }
    }

    .btn-collapse {
      width: 45px;
      height: 32px;
      border: unset;
      box-shadow: unset;
      background-color: transparent;
      cursor: pointer;
      &:hover {
        cursor: pointer;
      }
      &:focus {
        background-color: transparent;
      }
      &-box {
        width: 45px;
        height: 34px;
        position: absolute;
        top: 0;
        right: 0;
      }
      svg {
        top: 1px;
        position: relative;
      }
    }

    /* Not Checked */
    input[type="checkbox"].filled-in:not(:checked) + span.item-overlay:before {
      opacity: 0;
    }
    input[type="checkbox"].filled-in:not(:checked) + span:not(.lever):after,
    input[type="checkbox"].filled-in:not(:checked) + span.item-overlay:after,
    input[type="checkbox"].filled-in:not(:checked) + span.item-overlay::after {
      width: 100%;
      height: 100%;
      border-color: #dadada;
    }

    /* Checked */
    input[type="checkbox"].filled-in:checked
      + span.item-overlay:not(.lever):before,
    input[type="checkbox"].filled-in:checked
      + span.item-overlay:not(.lever)::before {
      opacity: 0;
    }

    input[type="checkbox"].filled-in:checked
      + span.item-overlay:not(.lever):after,
    input[type="checkbox"].filled-in:checked
      + span.item-overlay:not(.lever)::after {
      width: 100%;
      height: 100%;
      background-color: $color-label--green;
      border-color: $color-label--green;
    }
    input[type="checkbox"].filled-in:checked
      + span.item-overlay
      + span.item-text,
    input[type="checkbox"].filled-in:checked
      + span.item-overlay
      + span.item-text {
      color: white;
    }
  }
}

.special__icon--detail {
  background: $clr__red;
  color: $clr__white;
  font-weight: bold;
  line-height: 1.5rem;
  display: inline-block;
  width: auto;
  height: 20px;
  padding: 0 5px;
  font-size: 0.7rem;
  position: absolute;
  top: 10px;
  right: 0px;
  margin-left: 0 !important;
  border-radius: 5px;
}

/**
 * Floor Selector CSS
 */

.evacsafety__floorSelector {
  height: 50px;
  border: 2.5px solid $clr__white;
  color: white;
  border-radius: 5px;

  .left {
    width: 70%;
    height: 45px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: bold;

    .evacsafety__floorSelecor--label {
      padding-left: 4px;
      line-height: 2rem;
      height: (45px / 2);
      text-transform: uppercase;

      span {
        opacity: 0.5;
      }
    }

    .evacsafety__floorSelector--indicator {
      height: (45px / 2);
      line-height: 1.3rem;

      span {
        opacity: 0.5;
      }
    }
  }

  .right {
    width: 28%;
    height: 45px;
    margin-right: 2%;

    i.material-icons {
      width: 100%;
      height: 45px;
      text-align: center;
      line-height: 3rem;
      font-size: 2rem;
    }
  }
}

.evacsafety__floorSelector--popup {
  position: absolute;
  top: 20%;
  left: 10%;
  width: 80%;
  height: 60%;
  min-height: 400px;
  padding: 2.5%;
  background-color: white;
  border: 2px solid #707070;
  z-index: 10000;

  .evacsafety__floorSelector--popupSearch {
    position: relative;
    width: 100%;
    height: 10%;
    background: red;
    margin-bottom: 0;

    .col {
      width: 100%;
      height: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;

      input[type="text"] {
        display: block;
        width: 100%;
        height: 100%;
        padding: 10px;
        font-weight: bold;
        outline: none;
        font-size: 1.1rem;
      }
    }
  }

  $pResult-h: 90%;
  $pResult-top: 3%;
  .evacsafety__floorSelector--popupResults {
    position: relative;
    width: 100%;
    height: $pResult-h - $pResult-top;
    top: $pResult-top;
    overflow-y: scroll;
    overflow-x: hidden;

    .col {
      width: 100%;
      height: 15%;
      border-bottom: 1px solid #dadada;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-top: 5%;
      text-transform: capitalize;

      label {
        position: relative;
        display: block;
        height: 50%;
      }
    }
  }
}
