@import "../../../../scss/_colors";

.alert-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 15px;
  width: 100%;
  height: 100%;
  padding: 28px 18px 18px;

  &__img-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__message {
    text-align: center;
    font-size: 18px;
  }

  button:disabled,
  button[disabled] {
    background-color: $clr__gray !important;
    color: $clr__white !important;
    cursor: not-allowed !important;
  }

  & > button:hover {
    background-color: $clr__gray;
  }
}
