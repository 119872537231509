.warden-assignment {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
  }

  &__top-back-btn {
    position: absolute;
    left: 0;
    top: 0;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    :hover {
      background-color: transparent;
    }
    & > img {
      width: 18px;
      height: 20px;
    }
  }

  &__title {
    width: 239px;
    font-size: 24px;
    color: #000000;
    font-weight: 700;
  }

  &__form {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    &-section {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      padding: 25px;
    }

    &-section-item {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }

    &-label {
      font-size: 12px;
      color: #000000;
    }

    &-info {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      font-size: 16px;
      color: #333333;

      img {
        width: 22px;
        height: 22px;
      }

      &-details {
        display: flex;
        flex-direction: column;
        &-title {
          font-size: 16px;
        }
        &-subtitle {
          font-size: 14px;
        }
      }
    }

    &-reason-input {
      border: none;
      background-color: #ffffff;
      padding: 10px;
      min-height: 58px;
      margin-top: 5px;
      font-size: 12px;
    }

    &-actions {
      position: sticky;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      column-gap: 17px;
      width: 100%;
      margin-top: auto;
      background-color: #ffffff;
      padding: 17px 0px;

      &-primary-btn {
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 60px;
        font-size: 16px;
        font-weight: 600;
        border-radius: 6px;
        row-gap: 4px;
        & > img {
          margin: 0px;
        }
      }
      &-secondary-btn {
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 60px;
        font-size: 16px;
        font-weight: 600;
        border-radius: 6px;
        row-gap: 4px;
        & > img {
          margin: 0px;
        }
      }
    }
  }

  &__peep-list {
    background-color: #f2e8b2;
    border-radius: 4px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    scrollbar-width: none;
    overflow-y: auto;
  }

  &__peep-list-item {
    &--header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &--header-item {
      font-size: 14px;
      font-weight: 700;
    }

    p {
      font-size: 14px;
    }
  }
}
