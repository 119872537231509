@import "../../../../scss/_mixins";
@import "../../../../scss/_colors";

.evac__controls {
  width: 100%;
  background-color: black;
  padding-bottom: 20px;
  &-row {
    width: 100%;
  }
  .col {
    &-building {
      width: 100%;
      padding: 5px 0 8px 0;
      @include display-flex();
      @include align-items(center);
    }
    &-floor {
      width: 100%;
      padding: 5px 0 8px 0;
      @include display-flex();
      @include align-items(center);
    }
    &-status {
      width: 100%;
      padding: 5px 0 8px 0;
      @include display-flex();
      @include align-items(center);
    }
    &-label {
      width: 100px;
      color: white;
      font-weight: 600;
      line-height: normal;
    }
    &-select {
      width: calc(100% - 100px);
      font-weight: 600;
      font-size: 15px;
    }
  }
}

.alert {
  width: 100%;
  position: relative;
  &.alert--floorevac {
    padding: 0 15px;
  }
  &-row {
    width: 100%;
    border: 1px solid #e8c500;
    position: relative;
    z-index: 2;
    @include display-flex();
    @include border-radius(4px);
  }
  &-icon {
    width: 40px;
    background-color: #e8c500;
    @include display-flex();
    @include align-items(center);
    @include justify-content(center);
  }
  &-content {
    width: calc(100% - 40px);
    padding: 8px 10px;
    font-size: 13px;
    background-color: #f7f4e0;
  }
  &-bgoverlay {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    z-index: 1;
    background-color: $color-body;
  }
}

.collapse {
  &-content {
    width: 100%;
    position: relative;
    padding-top: 10px;
    .search-icon {
      position: absolute;
      top: 20px;
      left: 12px;
    }
    .search-input {
      width: 100%;
      padding: 10px 10px 10px 38px;
      font-size: 1.1rem;
      outline: none;
      background: white;
      border: 1px solid #ddd;
      border-radius: 5px;
      &::placeholder {
        color: #ccc;
      }
    }
  }
  &-disability,
  &-occupants {
    margin-top: 15px;
    padding: 6px 5px 6px 10px;
    background-color: #f5f5f6;
    @include border-radius(6px);
    .col {
      &-row {
        width: 100%;
        cursor: pointer;
        @include display-flex();
        @include justify-content(space-between);
        @include align-items(center);
      }
      &-left {
        width: calc(100% - 80px);
        h3 {
          font-size: 16px;
          font-weight: 700;
        }
      }
      &-right {
        width: 60px;
        @include display-flex();
        @include justify-content(flex-end);
        @include align-items(center);
        .counter {
          background-color: $color-label--purple;
          color: white;
          font-weight: 600;
          min-width: 28px;
          min-height: 30px;
          text-align: center;
          font-size: 14px;
          @include display-flex();
          @include justify-content(center);
          @include align-items(center);
          @include border-radius(4px);
        }
        .arrow {
          min-width: 30px;
          min-height: 35px;
          text-align: center;
          @include display-flex();
          @include justify-content(center);
          @include align-items(center);
        }
      }
      &-search {
        width: 100%;
        .search {
          &-row {
            width: 100%;
            @include display-flex();
            @include justify-content(space-between);
            @include align-items(center);
          }
          &-left {
            width: 100%;
            /* width: calc(100% - 40px);*/
          }
          &-right {
            width: 40px;
            display: none;
          }
        }
        @include keyframes(spin) {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .btn-refresh {
          background-color: #e1e1e1;
          border: 1px solid #cdcdcd;
          width: 35px;
          height: 35px;
          cursor: pointer;
          @include display-flex();
          @include align-items(center);
          @include justify-content(center);
          @include transition(all 0.3s ease);
          &.active {
            svg {
              @include animation(spin 0.6s infinite);
            }
          }
          &:hover {
            background-color: #ddd;
            svg {
              fill: #333;
            }
          }
          svg {
            fill: #a2a2a2;
            @include transition(all 0.3s ease);
          }
        }
      }
      &-peep {
        width: 100%;
        .error-msg {
          font-size: 18px;
          text-transform: uppercase;
          text-align: center;
          font-weight: 600;
          color: #9a9a9a;
          padding: 20px 0;
        }
      }
    }
  }
  &-occupants {
    .col-search {
      margin-bottom: 20px;
    }
  }
}

.evac__controls > .row {
  margin-bottom: 5px;
}

.evac__controls > .row > .col {
  text-align: right;
}

.evac__controls > .row > .col > .label {
  display: block;
  height: 40px;
  padding-top: 8px;
  font-size: 1.2rem;
  font-weight: bold;
}

.evac__controls > .row > .col select {
  border: 1px solid #2a2a2a;
}

.btn__update {
  width: 100%;
  text-transform: capitalize;
  margin-top: 10px;
}

.btn__update[disabled] {
  border: 1px solid gray;
}

.list__header {
  position: relative;
  width: 100%;
  height: 40px;
  font-size: 1.2rem;
  margin-top: 15px;
}

.peep__header {
  background: #f5f5f6;
}

.refusal__header {
  background: $clr__red;
}

.list__header > .container {
  width: 98%;
}

.list__header > .container .col {
  padding: 0px;
}

.list__header--refusal {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
}

.list__header--refusal > img {
  margin-top: 6px;
  width: 28px;
  height: 28px;
}

.header__icon {
  display: block;
  height: 40px;
  padding-top: 5px;
  text-align: center;
}

.header__icon > i {
  font-size: 2rem;
}

.header__title {
  display: block;
  height: 40px;
  font-weight: bold;
  padding-top: 5px;
  font-size: 1.4rem;
  text-align: center;
}

.header__count {
  display: block;
  height: 40px;
  font-weight: bold;
  padding-top: 5px;
  font-size: 1.4rem;
  text-align: center;
}

.header__collapsible {
  display: block;
  height: 40px;
  padding-top: 5px;
  text-align: center;
}

.header__collapsible > i {
  font-size: 2rem;
}

.peep__list {
  width: 98%;
  padding-top: 20px;
  padding-bottom: 10px;
}

.peep__list--item {
  border-bottom: 1px solid black;
  padding-top: 10px;
  margin-bottom: 10px;
}

.peep__list--note {
  $peeppad: 10px;
  color: $clr__black;
  padding: $peeppad;
  background: $clr__yellow;
}

.peep__image {
  display: block;
  background: white;
  width: auto;
  width: 80px;
  height: 80px;
  max-width: 80px;
  max-height: 80px;
  border-radius: 100px;
  border: 2px solid $clr__peep;
}

.peep__image > img {
  position: relative;
  width: 95%;
  height: 95%;
  top: 2.5%;
  left: 2.5%;
  border-radius: 100px;
}

.peep__info {
  display: block;
  width: 100%;
  height: 120px;
}

.peep__info > .col {
  padding: 0;
  padding-right: 5px;
  margin-bottom: 5px;
}

.peep__info > .col > select {
  border: 1px solid #2a2a2a;
}

.no__peep {
  position: relative;
  width: 100%;
  height: 40px;
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  color: $clr__peep;
}

.info__name {
  font-weight: bold;
  display: block;
  font-size: 1.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info__phone {
  font-weight: lighter;
  display: block;
  font-size: 0.9rem;
}

.info__location {
  font-weight: lighter;
  display: block;
  font-size: 0.9rem;
}

.info__badge {
  height: 45px;
  text-align: left;
}

.peep__badge {
  background: $clr__peep;
  position: relative;
  top: 10px;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-weight: 800;
  font-size: 0.8rem;
  animation: shimmer 1s infinite;
  letter-spacing: 3px;
}

.person__search {
  width: 98%;
  padding: 10px 5px;
}

.person__search > .input__search {
  width: 100%;
  padding: 5px 10px;
  font-size: 1.1rem;
  outline: none;
  background: white;
  border: 1px solid #7e7e7e;
  border-radius: 5px;
}

.person__search > .input__search::placeholder {
  color: #000;
}
.person__list {
  width: 98%;
  padding-top: 20px;
  padding-bottom: 10px;
}

.person__list--item {
  border-bottom: 1px solid black;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.person__info {
  width: 100%;
  height: 75px;
  text-align: left;
}

.person__name {
  font-size: 1.3rem;
  font-weight: 800;
}

.person__phone,
.person__location {
  font-size: 0.9rem;
}

.person__reported > .col {
  padding: 0 !important;
}

.lever {
  width: 50px !important;
  height: 25px !important;
}

.lever::before {
  background-color: white !important;
  left: 0px !important;
  top: 0px !important;
  width: 25px !important;
  height: 25px !important;
  display: none !important;
}

.lever::after {
  background-color: white !important;
  left: 0px !important;
  top: 0px !important;
  width: 25px !important;
  height: 25px !important;
  opacity: 1;
  transition: all 0.5s;
}

.reported::after {
  opacity: 1;
  left: 25px !important;
}

.reported::before {
  opacity: 0;
}

.reported__switch {
  width: 100%;
  height: 30px;
  padding: 0px !important;
  margin-top: 10px;
  text-align: center;
}

.reported__text {
  width: 100%;
  height: 25px;
  text-align: center;
  padding-top: 2.5px;
}

.PeepPopUp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 428px;
  height: auto;
  background: white;
  z-index: 999999;
  border-radius: 5px;
}
.PeepPopUp__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.PeepPopUp__Header {
  background: $clr__peep;
  height: 45px;
  font-weight: 800;
  font-size: 2rem;
  color: #fff;
  text-align: center;
  border-radius: 5px 5px 0px 0px;
}

.PeepPopUp__Content {
  width: 85%;
}

.PeepPopUp__Content > .row:nth-child(odd) {
  margin: 0px;
}

.PeepPopUp__Content > .row > .col {
  margin: 0;
}

.PeepPopUp__Content > .row:nth-child(odd) > .col {
  font-weight: 800;
  font-size: 1.2rem;
}

.PeepPopUp__box {
  max-height: 100px;
  padding: 8px;
  border: 1px solid #707070;
  border-radius: 10px;
  margin-top: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.PeepPopUp__HeaderIcon {
  width: 10%;
  padding-top: 5px;
  text-align: center;
}

.PeepPopUp__HeaderName {
  width: 90%;
}

.reported__button {
  text-align: center;
  button {
    width: 45px;
    height: 45px;
    padding: 2px;
    background: none;
    border: none;
    color: $clr__white;
    border-radius: 10px;
    transition: all 0.5s;
    color: $clr__white;
    border: 1px solid #7e7e7e;
    img {
      width: 100%;
      height: auto;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      opacity: 0.6;
    }
  }
  button.reported__button--active {
    border: 1px solid $clr__black;
    background-color: $clr__white;
    img {
      filter: none;
      -webkit-filter: none;
      opacity: 1;
    }
  }
  button.reported__button--inactive {
    background: #dadada;
  }
}

@keyframes shimmer {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

@media screen and (min-width: 320px) {
  .header__title {
    font-size: 1.25rem;
  }
}
