/**
  * UTILITY CLASSES
  */

/**
  Padding classes
 */
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

/**
  Margin Classes
 */

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-5 {
  margin-bottom: 5px;
}

.bt-20p {
  bottom: 20%;
}

.no-uppercase {
  text-transform: none;
}

.no-margin {
  margin: 0;
}

.center-text {
  width: 100%;
  text-align: center;
}

.h-flip {
  transform: scale(-1, 1);
}

.v-flip {
  transform: scale(1, -1);
}

.br-10 {
  border-radius: 10px !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.extended {
  width: 98% !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}
