@mixin transition($value) {
    -webkit-transition: $value;
    -moz-transition: $value;
    -ms-transition: $value;
    -o-transition: $value;
    transition: $value;
}

@mixin transition-two($value, $prop) {
    -webkit-transition: $value, $prop;
    -moz-transition: $value, $prop;
    -ms-transition: $value, $prop;
    -o-transition: $value, $prop;
    transition: $value, $prop;
}
