@import "../../../../scss/_colors";

.ns__toast {
  position: fixed;
  bottom: 40%;
  left: 5%;
  width: 90%;
  min-height: 50px;
  z-index: 999999999;
  border-radius: 5px;
  padding: 15px 15px;
  font-size: 1.1rem;
  text-align: center;
  color: #fff;
  box-shadow: 0px 0px 20px 5px rgba(82, 82, 82, 1);
}

.ns__toast--success {
  background-color: $clr__green;
}

.ns__toast--warning {
  background-color: $clr__yellow;
}

.ns__toast--danger {
  background-color: $clr__red;
}
