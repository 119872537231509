@import "../../../../../scss/_colors";
@import "../../../../../scss/_mixins";

.messagebox__container {
  position: fixed;
  width: 100%;
  height: auto;
  bottom: 0;
  margin-bottom: 0px !important;
  // border-top: 1px solid #7e7e7e;
  // background-color: #eeeeee;
}

.messagebox__actionarea {
  position: relative;
  border-top: 1px solid #DDD;
  background-color: white;
  padding: 10px 0 15px 0 !important;
  @include display-flex();
  @include justify-content(center);
  @include align-items(center);
}

.messagebox__templatebtn {
  height: 100%;
  text-align: center;
  padding-bottom: 5px !important;
  cursor: pointer;
  @include display-flex();
  @include justify-content(center);
  @include align-items(center);
}

.messagebox__templatebtn--img {
  width: 100%;
  margin-top: 10px;
  height: auto;
}

.messagebox__input {
  position: relative;
  height: auto;
  min-height: 42px !important;
  padding: 0 !important;
}

.messagebox__input--field,
.messagebox__input--focus {
  width: 100%;
  min-height: 50px;
  max-height: 120px;
  border-radius: 10px;
  background-color: #fff;
  outline: none;
  resize: none;
  overflow: hidden;
  padding: 10px 8px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.messagebox__input--field {
  border: 1px solid #d8d8d8;
}

.messagebox__input--field:disabled {
  background: #e9e9e9;
}

.messagebox__input--focus {
  border: 1px solid $clr__red;
}

.messagebox__input--button {
  width: 100%;
  height: 100%;
  height: 40px;
  background: none;
  text-transform: capitalize;
  border: none;
  font-size: 1.1rem;
  font-weight: 700;
}

.messagebox__input--button:focus {
  background: none;
}

.messagebox__input--button:disabled {
  color: #dadada;
}

.message_indicator {
  position: relative;
  width: 60px;
  height: 25px;
  top: -10px;
}

.message_indicator_img {
  position: absolute;
  right: 0px;
  width: 60px;
  height: auto;
  padding: 5px;
}

.blacknwhite {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

@media screen and (min-width: 768px) {
  .messagebox__container {
    bottom: 65px;
  }
}
