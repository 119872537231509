@import "../../../../scss/_colors";
@import "../../../../scss/_skeleton";
@import "../../../../scss/_mixins";
@import "../../../../scss/_components";

$floorwarden: 'floorwarden';
.#{$floorwarden} {
  &-head {
    width: 100%;
    background-color: black;
    padding-bottom: 10px;
  }
  &__building {
    width: 100%;
    @include display-flex();
    @include align-items(center);
    &-label {
      width: 100px;
      color: white;
      font-weight: 600;
      line-height: normal;
    }
    &-select {
      width: calc(100% - 100px);
      font-weight: 600;
      font-size: 15px;
    }
  }
  &__displaybar {
    width: 100%;
    padding: 10px 5px 10px 0;
    position: relative;
    @include display-flex();
    @include justify-content(space-between);
    @mixin act-label {
      width: calc(100% - 30px);
      font-size: 14px;
      text-transform: capitalize;
      font-weight: 600;
      padding-right: 5px;
      @media all and (max-width: 370px) {
        font-size: 10px;
      }
    }
    @mixin act-count {
      overflow: hidden;
      font-size: 14px;
      font-weight: 600;
      max-width: 30px;
      min-width: 30px;
      @include border-radius(4px);
      @media all and (max-width: 370px) {
        font-size: 10px;
      }
    }
    .act {
      border-radius: 4px;
      text-align: center;
      @include display-flex();
      @include align-items(center);
      @include justify-content(space-between);
      &:hover {
        cursor: pointer;
      }
      &--onduty {
        width: calc(33.333% - 8px);
        padding: 4px 4px 5px 4px;
        background-color: white;
        .label {
          @include act-label();
          color: $color-label--green;
        }
        .counter {
          @include act-count();
          background-color: $color-label--green;
          color: white;
        }
        &.on--active {
          background-color: $color-label--green;
          .label {
            color: white;
          }
          .counter {
            background-color: white;
            color: $color-label--green;
          }
        }
      }
      &--unknown {
        width: calc(33.333% - 8px);
        padding: 4px 4px 5px 4px;
        background-color: white;
        margin: 0 2px;
        .label {
          @include act-label();
          color: $color-label--orange;
        }
        .counter {
          @include act-count();
          background-color: $color-label--orange;
          color: white;
        }
        &.on--active {
          background-color: $color-label--orange;
          .label {
            color: white;
          }
          .counter {
            background-color: white;
            color: $color-label--orange;
          }
        }
      }
      &--notavail {
        width: calc(33.333% - 8px);
        background-color: white;
        padding: 4px 4px 5px 4px;
        .label {
          @include act-label();
          color: $color-label--red
        }
        .counter {
          @include act-count();
          background-color: $color-label--red;
          color: white;
        }
        &.on--active {
          background-color: $color-label--red;
          .label {
            color: white;
          }
          .counter {
            background-color: white;
            color: $color-label--red;
          }
        }
      }
      &--offduty,
      &--total {
        display: none;
        width: calc(33.333% - 8px);
        background-color: white;
        padding: 4px 4px 5px 4px;
        .label {
          @include act-label();
          color: $color-body;
        }
        .counter {
          @include act-count();
          background-color: $color-grey-200;
          color: white;
        }
        &.on--active {
          background-color: white;
          .label {
            color: $color-body;
          }
          .counter {
            background-color: $color-body;
            color: white;
          }
        }
      }
    }
  }
  &__list-head {
    width: 100%;
    position: relative;
    &-row {
      width: 100%;
      margin: 0;
      padding: 14px 0;
      background-color: #DDD;
      font-weight: 600;
      position: relative;
      z-index: 3;
      @include border-radius(4px);
      @include display-flex();
    }
    &-left {
      width: 55%;
      padding: 0 15px;
      @media all and (max-width: 500px) {
        width: 45%;
      }
    }
    &-right {
      width: 45%;
      @include display-flex();
      @media all and (max-width: 500px) {
        width: 55%;
      }
      &-status {
        width: calc(100% - 90px);
        padding-left: 3px;
      }
      &-warden {
        width: 110px;
        @media all and (max-width: 400px) {
          width: 92px;
          padding-left: 2px;
        }
      }
    }
    &-overlay {
      width: 100%;
      height: 50%;
      z-index: 1;
      position: absolute;
      top: 0;
      background-color: black;
      left: 0;
    }
  }
  &__lists {
    width: 100%;
    padding-bottom: 30px;
    .item {
      width: 100%;
      &-row {
        width: 100%;
        padding-top: 18px;
        @include display-flex();
      }
      &-left {
        width: 55%;
        padding-right: 15px;
        @media all and (max-width: 500px) {
          width: 45%;
          padding-right: 8px;
        }
      }
      &-right {
        width: 45%;
        @include display-flex();
        @include align-items(flex-start);
        @include justify-content(space-between);
        @media all and (max-width: 500px) {
          width: 55%;
        }
      }
      &-arrow {
        position: absolute;
        top: 50%;
        right: 10px;
        @include transform(translateY(-50%));
        @media all and (max-width: 400px) {
          svg {
            width: 12px;
            height: 12px;
          }
        }
        @media all and (max-width: 320px) {
          svg {
            width: 10px;
            height: 10px;
          }
        }
      }
      &-place {
        width: 100%;
        position: relative;
        button {
          width: 100%;
          height: 43px;
          padding: 10px;
          text-align: left;
          background-color: white;
          border: 1px solid #DDD;
          cursor: pointer;
          @include border-radius(4px);
          &:hover {
            cursor: pointer;
          }
          @media all and (max-width: 500px) {
            font-size: 15px;
          }
          @media all and (max-width: 380px) {
            font-size: 14px;
          }
        }
        &-empty-warden {
          width: 100%;
          height: 43px;
          padding: 10px;
          display: block;
          text-align: left;
          background-color: white;
          border: 1px solid #DDD;
          @include border-radius(4px);
        }
      }
      &-status {
        width: calc(100% - 110px);
        height: 43px;
        min-height: 43px;
        padding: 0 10px;
        color: white;
        font-size: 15px;
        line-height: normal;
        @include display-flex();
        @include justify-content(space-between);
        @include align-items(center);
        @include floor-warden-status();
        @media all and (max-width: 400px) {
          width: calc(100% - 92px);
        }
        &-box {
          width: 100%;
          @include border-radius(4px);
          @media all and (max-width: 500px) {
            font-size: 15px;
          }
          @media all and (max-width: 400px) {
            font-size: 12px;
            line-height: 1.1;
          }
        }
      }
      &-warden {
        width: 110px;
        padding-left: 8px;
        @include display-flex();
        @include justify-content(space-between);
        @include align-items(center);
        @media all and (max-width: 500px) {
          padding-left: 5px;
        }
        @media all and (max-width: 400px) {
          width: 92px;
          padding-left: 2px;
        }
        &-counter {
          min-width: 30px;
          margin-left: 4px;
          max-width: calc(33.333% - 5px);
          height: 43px;
          color: white;
          text-align: center;
          font-weight: 600;
          @include display-flex();
          @include justify-content(center);
          @include align-items(center);
          @include border-radius(2px);
          @include floor-warden-status();
          @media all and (max-width: 500px) {
            font-size: 15px;
          }
          @media all and (max-width: 400px) {
            font-size: 14px;
            min-width: 25px;
          }
        }
      }
      &-detail {
        width: 100%;
        position: relative;
        &-caption {
          width: 100%;
          font-weight: 600;
          padding: 15px 0 10px 35px;
          font-size: 16px;
          color: $color-label--red;
        }
        &-box {
          width: 100%;
          padding: 10px 10px 15px 10px;
          background-color: #ebebeb;
        }
      }
    }
  }
}
