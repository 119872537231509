@import "../../../../../scss/_colors", "../../../../../scss/_skeleton";
@import "../../../../../scss/_mixins";

.message__recipient {
  position: relative;
  width: 100%;
  // min-height: 100px;
  max-height: 25vh;
  background-color: black;
  margin-bottom: 0 !important;
  top: 0px;
}

.recipient {
  &__back {
    width: 50px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    height: 100%;
    padding-left: 5px;
    @include display-flex();
    @include align-items(center);
    &--btn {
      width: 100%;
      height: 100%;
      @include display-flex();
      @include align-items(center);
    }
  }
  &__info {
    width: 100%;
    position: relative;
    padding: 5px 0 10px 50px;
    @include display-flex();
    @include flex-wrap(wrap);
    .identity {
      width: 100%;
      position: relative;
      @include display-flex();
      &__left {
        width: 13px;
        position: relative;
        @include display-flex();
        @include align-items(center);
      }
      &__middle {
        padding-left: 10px;
        &.middle--warden {
          width: calc(100% - 90px);
        }
        &.middle--evacuee {
          width: calc(100% - 60px);
        }
      }
      &__right {
        &--evacuee {
          width: 60px;
          @include display-flex();
          @include align-items(flex-end);
          @include justify-content(center);
          .box {
            &-contact {
              @include display-flex();
              @include align-items(center);
              @include justify-content(center);
              a {
                width: 40px;
                height: 40px;
                padding: 3px;
                position: relative;
                background-color: $color-label--blue;
                @include display-flex();
                @include align-items(center);
                @include justify-content(center);
                @include border-radius(6px);
              }
              img {
                width: 100%;
                display: block;
                @include transform(scale(0.8));
              }
            }
          }
        }
        &--warden {
          width: 90px;
          padding-right: 10px;
          @include display-flex();
          @include align-items(center);
          @include justify-content(space-between);
          .box {
            &-contact {
              @include display-flex();
              @include align-items(center);
              @include justify-content(center);
              a {
                width: 34px;
                height: 34px;
                padding: 3px;
                display: block;
                background-color: $color-label--blue;
                @include border-radius(6px);
              }
              img {
                width: 100%;
                display: block;
                @include transform(scale(0.8));
              }
              svg {
                position: relative;
                left: 4px;
              }
            }
            &-phonelink {
              width: 34px;
              height: 34px;
              padding: 8px;
              background-color: $color-label--blue;
              border: none;
              @include border-radius(6px);
              @include display-flex();
              @include align-items(center);
              @include justify-content(center);
              &:hover {
                cursor: pointer;
              }
              img {
                width: 100%;
                display: block;
              }
            }
          }
        }
      }
    }
  }
  &__status {
    &--badge {
      width: 100%;
      min-height: 90%; //39px
      max-height: 90%;
      position: absolute;
      bottom: 0;
      left: 0;
      text-indent: -99999px;
      font-size: 0px;
      @include border-radius(1px);
    }
  }
  &__name {
    font-size: 18px;
    font-weight: 600;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: white;
  }
  &__role {
    color: white;
  }
  &__location {
    color: white;
    font-size: 14px;
  }
}
