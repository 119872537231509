@import "../../../../scss/_mixins";
@import "../../../../scss/_colors";
@import "../../../../scss/_components";

@mixin checkbox-button-selector($value) {
  input[type="checkbox"]+span {
    border: 2px solid $value;
  }
  input[type="checkbox"]:checked+span,
  input:checked+span {
    background-color: $value;
  }
}

@mixin checkbox-button {
  .custom-checkbox{
    width: calc(33.333% - 10px);
    text-align: center;
    background-color: black;
    overflow: hidden;
    font-size: 16px;
    @include border-radius(4px);
    @media all and (max-width: 370px) {
      width: calc(33.333% - 5px);
    }
    label {
      width: 100%;
      font-size: 16px;
      @media all and (max-width: 480px) {
        font-size: 14px;
      }
      @media all and (max-width: 380px) {
        font-size: 12px;
      }
      span {
        text-align: center;
        padding: 6px 0 6px 0;
        text-transform: capitalize;
        display: block;
      }
      input {
        position: absolute;
        display: none;
        color: #fff !important;
      }
    }
    label input+span {
      color: #fff;
    }
    input:checked+span {
      color: #ffffff;
    }
    &.status {
      &--onduty {
        @include checkbox-button-selector($color-label--green);
      }
      &--unknown {
        @include checkbox-button-selector($color-label--orange);
      }
      &--offduty,
      &--notavailable {
        @include checkbox-button-selector($color-label--red);
      }
      &--default {
        @include checkbox-button-selector(white);
        input[type="checkbox"]:checked+span,
        input:checked+span {
          color: black;
        }
      }
    }
  }

  /* Overwrite:
    unset height
    hide check mark from materialize
  */
  input[type="checkbox"],
  input[type="checkbox"]+span:not(.lever){
    font-size: inherit;
    height: auto;
    line-height: normal;
  }
  input[type="checkbox"]+span:before,
  input[type="checkbox"]+span:after {
    display: none !important;
  }
}

$allmessage: "allmessage";
.#{$allmessage} {
  &__view {
    width: 100%;
    height: 50px;
    position: relative;
    background-color: black;
  }
  &__viewBtnContainer {
    width: 100%;
    height: 35px;
    padding-right: 10px;
    position: relative;
    display: inline-block;
    margin: 8px 0;
    @include border-radius(5px);
  }
  &__viewBtn {
    width: 50%;
    height: 33px;
    text-align: center;
    position: relative;
    background-color: #e8e9ed;
    color: #a2a2a2;
    display: inline-block;
    padding: 5px 10px;
    text-transform: capitalize;
    font-weight: 700;
    border: none;
    cursor: pointer;
    @media all and (min-width: 480px) {
      width: 140px;
    }
    &--selected {
      background-color: white;
      color: black;
    }
    &:first-child {
      @include border-radius(4px 0 0 4px);
    }
    &:last-child {
      @include border-radius(0 4px 4px 0);
    }
  }
  &-head {
    width: 100%;
    background-color: black;
    position: relative;
    padding: 10px 10px 0 10px;
    .container {
      @media all and (max-width: 480px) {
        padding-left: 6px;
      }
    }
    .col {
      &-row {
        width: 100%;
        padding: 5px 0 8px 0;
        @include display-flex();
        &.items-center {
          @include align-items(center);
        }
      }
      &-left {
        width: 100px;
        color: white;
        font-weight: 600;
        line-height: normal;
        &.items-center-select {
          padding-top: 12px;
        }
        &.items-center-msg {
          padding-top: 5px;
        }
      }
      &-right {
        width: calc(100% - 100px);
        font-weight: 600;
        font-size: 15px;
        &.row-message {
          @include display-flex();
          @include justify-content(space-between);
        }
        .box {
          &-checkboxes {
            width: 100%;
            background-color: white;
            padding: 14px 10px;
            position: relative;
            text-align: left;
            border: unset;
            cursor: pointer;
            @include border-radius(4px);
            @include display-flex();
            &:hover {
              cursor: pointer;
            }
            &-text {
              width: calc(100% - 30px);
              font-weight: bold;
              font-size: 16px;
            }
            &-icon {
              height: 100%;
              position: absolute;
              top: 0;
              right: 12px;
              text-align: center;
              @include display-flex();
              @include align-items(center);
              @include justify-content(center);
              svg {
                position: relative;
              }
            }
            &-accordion {
              width: 100%;
              background-color: white;
              position: relative;
              top: -2px;
              padding-top: 5px;
              .checkbox {
                &-parent {
                  position: relative;
                  padding: 5px 5px 5px 15px;
                }
                &-child {
                  position: relative;
                  padding: 5px 5px 5px 25px;
                }
                &-text {
                  color: black;
                  font-size: 1.1rem;
                  text-transform: uppercase;
                }
              }
            }
          }
          &-statuses {
            width: 100%;
            background-color: black;
            @include display-flex();
            @include align-items(center);
            @include justify-content(space-between);
            @include checkbox-button();
          }
          &-counter {
            height: 32px;
            padding: 0 5px;
            background-color: black;
            border: 1px solid $color-label--blue;
            @include display-flex();
            @include align-items(center);
            @include border-radius(4px);
            @media all and (max-width: 400px) {
              width: 100%;
            }
            &-text {
              color: white;
              padding-right: 5px;
              @media all and (max-width: 380px) {
                font-size: 14px;
              }
            }
            &-amount {
              max-width: 30px;
              min-width: 30px;
              margin-left: 4px;
              font-weight: 600;
              font-size: 14px;
              background-color: white;
              @include display-flex();
              @include align-items(center);
              @include justify-content(center);
              @include border-radius(4px);
            }
          }
          &-broadcast {
            @media all and (max-width: 480px) {
              width: auto;
              margin: 0 5px;
            }
            a {
              width: 100%;
              height: 32px;
              background-color: $color-label--blue;
              padding: 0 5px;
              @include display-flex();
              @include align-items(center);
              @include justify-content(center);
              @include border-radius(4px);
              span {
                color: white;
                position: relative;
                top: 1px;
                @media all and (max-width: 380px) {
                  font-size: 14px;
                  top: 0;
                }
              }
              svg {
                margin-left: 8px;
                @media all and (max-width: 480px) {
                  margin-left: 0;
                  display: none;
                }
              }
            }
          }
          &-clear {
            width: 80px;
            @media all and (max-width: 480px) {
              width: auto;
            }
            button {
              width: 100%;
              // height: 32px;
              background-color: $color-label--blue;
              color: white;
              border: none;
              padding: 9px 5px 7px 5px;
              font-weight: 600;
              font-size: 15px;
              cursor: pointer;
              @include border-radius(4px);
              @media all and (max-width: 380px) {
                font-size: 14px;
              }
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.wardenmessaging__search {
  padding-top: 10px;
  position: relative;
  width: 100%;
  height: 75px;
  @include search-field();
  .search {
    &__bgoverlay {
      height: 50%;
    }
  }
}