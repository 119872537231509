@mixin search-field {
  .search {
    &__field {
      width: 100%;
      position: relative;
      padding-left: 5px;
      padding-right: 5px;
    }
    &__bgoverlay {
      width: 100%;
      height: 35%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: black;
    }
    &__input {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 48px;
      background: white;
      outline: none;
      border: none;
      padding-left: 45px;
      padding-right: 42px;
      font-size: 1.1rem;
      z-index: 1;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      @include border-radius(5px);
    }
    &__icon--magnifying-glass {
      position: absolute;
      top: 60%;
      left: 20px;
      z-index: 2;
      @include transform(translateY(-60%));
    }
    &__clear {
      width: 40px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 5px;
      z-index: 2;
      background: unset;
      border: unset;
      box-shadow: unset;
      cursor: pointer;
      svg {
        position: relative;
        top: 0px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

@mixin attr-status-modal {
  /* STATUS MODAL */
  &-modal {
    width: 150px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 6;
    background-color: white;
    padding: 7px 5px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
      rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    .status {
      width: 100%;
      margin-bottom: 5px;
      padding: 12px;
      @include justify-content(center);
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-overlay {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 4;
      background: rgba(0, 0, 0, 0.4);
    }
  }
  /* END STATUS MODAL */
}

@mixin attr-refusal {
  .refusal {
    width: 40px;
    height: 40px;
    background-color: $color-inactive;
    border: 1px solid #969696;
    padding: 2px;
    cursor: pointer;
    @include display-flex();
    @include justify-content(center);
    @include align-items(center);
    @include align-self(center);
    @include border-radius(6px);
    img {
      width: 100%;
      height: auto;
      -webkit-filter: grayscale(100%);
      /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      opacity: 0.6;
    }
    &.active {
      background-color: $color-active;
      border: 1px solid $color-label--red;
      img {
        filter: none;
        -webkit-filter: none;
        opacity: 1;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
}

@mixin attr-status {
  .status {
    min-width: 80px;
    padding: 5px;
    min-height: 27.8px;
    box-shadow: unset;
    border: unset;
    cursor: pointer;
    @include border-radius(4px);
    @include display-flex();
    @include flex-wrap(wrap);
    @include align-items(center);
    &:focus,
    &:active,
    &:hover {
      cursor: pointer;
      box-shadow: unset;
      border: unset;
      outline: unset !important;
    }
    &-row {
      width: 100%;
      @include display-flex();
      @include flex-wrap(wrap);
      @include align-items(center);
    }
    &-text {
      color: white;
      padding-right: 5px;
      font-size: 12px;
      &.uppercase {
        text-transform: uppercase;
      }
      &.false {
        display: none;
      }
      &.show {
        display: block;
      }
    }
    &-location {
      padding: 2px 5px;
      font-size: 12px;
      background-color: white;
      @include display-flex();
      @include align-items(center);
      @include border-radius(4px);
      @include text_truncated(1);
    }

    /* STATUS VARIANTS */
    &.status--safe,
    &.status--safeonfloor {
      background-color: $color-label--green;
      .status {
        &-location {
          color: $color-label--green;
        }
      }
    }
    &.status--unknown {
      background-color: $color-label--orange;
      .status {
        &-location {
          color: $color-label--orange;
        }
      }
    }
    &.status--danger,
    &.status--indanger {
      background-color: $color-label--red;
      .status {
        &-location {
          color: $color-label--red;
        }
      }
    }
    /* END STATUS VARIANTS */

    /* STATUS MODAL */
    @include attr-status-modal();
    /* END STATUS MODAL */
  }
}

@mixin attr-status-dropdown-variants {
  &.status {
    &--black {
      background-color: black;
      color: white;
    }
    &--blue {
      background-color: $color-label--blue;
    }
    &--green {
      background-color: $color-label--green;
    }
    &--grey {
      background-color: $color-grey-900;
    }
    &--purple {
      background-color: $color-label--purple;
    }
    &--red {
      background-color: $color-label--red;
    }
    &--yellow {
      background-color: $color-label--orange;
    }
    &--default,
    &--white {
      background-color: white;
      color: black;
    }
  }
}

@mixin attr-status-dropdown {
  .status {
    min-width: 150px;
    padding: 10px;
    color: white;
    border: unset;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    @include box-shadow(unset);
    @include border-radius(4px);
    @include display-flex();
    @include flex-wrap(wrap);
    @include align-items(center);
    &:focus,
    &:active,
    &:hover {
      cursor: pointer;
      border: unset;
      outline: unset !important;
      @include box-shadow(unset);
    }

    /* STATUS VARIANTS */
    @include attr-status-dropdown-variants();
    /* END STATUS VARIANTS */

    /* STATUS MODAL */
    @include attr-status-modal();
    &-modal {
      width: auto;
      max-width: 100%;
      padding: 7px;
    }
    /* END STATUS MODAL */
  }
}

$card__evacuee: "card__evacuee";
.#{$card__evacuee} {
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 0 5px 20px 5px;
  margin-bottom: 15px;
  @include display-flex();
  @include flex-wrap(wrap);
  @include align-items(center);
  &-name {
    width: 100%;
    font-size: 1.4rem;
    font-weight: 800;
  }
  &-current-location {
    width: 100%;
    font-size: 1.2rem;
  }
  &-status {
    position: relative;
    display: flex;
    flex-direction: row;
    @include attr-status();
  }
  &-left {
    width: 60%;
    @include display-flex();
    @include flex-wrap(wrap);
  }
  &-right {
    width: 40%;
    @include display-flex();
    @include align-items(center);
    @include flex-wrap(wrap);
    @include justify-content(flex-end);
    .indicators {
      padding: 4px 0;
      @include display-flex();
      @include align-items(center);
      @include justify-content(flex-end);
      @include flex-wrap(wrap);
      // Order by flex
      .message-icon {
        order: 1;
      }
      .special__icon--refusal {
        order: 2;
      }
      .info-peep {
        order: 3;
        border: none;
      }
      .cta {
        order: 4;
      }
    }
    .cta {
      padding: 4px 0;
      @include display-flex();
      @include align-items(center);
      @include justify-content(flex-end);
    }
    .btn {
      &-peep {
        width: 40px;
        background-color: $color-label--purple;
        box-shadow: unset;
        border: unset;
        color: white;
        text-transform: uppercase;
        text-align: center;
        padding: 4px 0;
        font-size: 11px;
        @include border-radius(2px);
      }
      &-message {
        position: relative;
        padding-left: 4px;
        a {
          width: 40px;
          height: 40px;
          background-color: $color-label--blue;
          @include display-flex();
          @include align-items(center);
          @include justify-content(center);
          @include border-radius(6px);
        }
      }
      &-phone {
        position: relative;
        padding-left: 4px;
        a {
          width: 40px;
          height: 40px;
          background-color: $color-label--blue;
          @include display-flex();
          @include align-items(center);
          @include justify-content(center);
          @include border-radius(6px);
        }
      }
    }
    .info-peep {
      width: 28px;
      height: 30px;
      background-color: $color-label--purple;
      @include display-flex();
      @include align-items(center);
      @include justify-content(center);
      @include border-radius(4px);
    }
  }
}

$card__peeplist: "card__peeplist";
.#{$card__peeplist} {
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 20px 5px 20px 5px;
  margin-bottom: 15px;
  @include display-flex();
  @include flex-wrap(wrap);
  @include align-items(center);
  &:last-child {
    border-bottom: unset;
  }
  &-name {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 800;
  }
  &-status {
    position: relative;
    @include attr-status();
  }
  &-left {
    width: calc(100% - 90px);
    @include display-flex();
    @include flex-wrap(wrap);
    .attr {
      width: 100%;
      margin-top: 5px;
      @include display-flex();
    }
  }
  &-right {
    width: 90px;
    @include display-flex();
    @include align-items(center);
    @include flex-wrap(wrap);
    @include justify-content(flex-end);
    .cta {
      padding: 4px 0;
      @include display-flex();
      @include align-items(center);
      @include justify-content(flex-end);
    }
    .btn {
      &-message {
        position: relative;
        padding-left: 4px;
        a {
          width: 40px;
          height: 40px;
          background-color: $color-label--blue;
          @include display-flex();
          @include align-items(center);
          @include justify-content(center);
          @include border-radius(6px);
        }
      }
      &-phone {
        position: relative;
        padding-left: 4px;
        a {
          width: 40px;
          height: 40px;
          background-color: $color-label--blue;
          @include display-flex();
          @include align-items(center);
          @include justify-content(center);
          @include border-radius(6px);
        }
      }
    }
  }
}

$card_occupant: "card__occupant";
.#{$card_occupant} {
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 0 5px 20px 5px;
  margin-bottom: 15px;
  @include display-flex();
  @include flex-wrap(wrap);
  @include align-items(center);
  &:last-child {
    border-bottom: unset;
    margin-bottom: 0;
  }
  &-name {
    width: 100%;
    font-size: 1.4rem;
    font-weight: 800;
  }
  &-current-location {
    width: 100%;
    font-size: 1.2rem;
  }
  &-status {
    position: relative;
    @include attr-status();
  }
  &-additional {
    width: 100%;
    @include display-flex();
    .info-peep {
      width: 26px;
      height: 28px;
      margin-left: 5px;
      background-color: $color-label--purple;
      border: none;
      @include display-flex();
      @include align-items(center);
      @include justify-content(center);
      @include border-radius(4px);
    }
  }
  &-refusal {
    padding-left: 4px;
    position: relative;
    @include attr-refusal();
  }
  &-left {
    width: 60%;
    @include display-flex();
    @include flex-wrap(wrap);
  }
  &-right {
    width: 40%;
    @include display-flex();
    @include align-items(center);
    @include flex-wrap(wrap);
    @include justify-content(flex-end);
    .indicators {
      padding: 4px 0;
      @include display-flex();
      @include align-items(center);
      @include justify-content(flex-end);
      @include flex-wrap(wrap);
    }
    .cta {
      padding: 4px 0;
      @include display-flex();
      @include align-items(center);
      @include justify-content(flex-end);
    }
    .btn {
      &-peep {
        width: 40px;
        background-color: $color-label--purple;
        box-shadow: unset;
        border: unset;
        color: white;
        text-transform: uppercase;
        text-align: center;
        padding: 4px 0;
        font-size: 11px;
        @include border-radius(2px);
      }
      &-message {
        position: relative;
        padding-left: 4px;
        a {
          width: 40px;
          height: 40px;
          background-color: $color-label--blue;
          @include display-flex();
          @include align-items(center);
          @include justify-content(center);
          @include border-radius(6px);
        }
      }
      &-phone {
        position: relative;
        padding-left: 4px;
        a {
          width: 40px;
          height: 40px;
          background-color: $color-label--blue;
          @include display-flex();
          @include align-items(center);
          @include justify-content(center);
          @include border-radius(6px);
        }
      }
    }
  }
}

@mixin floor-warden-status {
  &.on-duty {
    background-color: $color-label--green;
  }
  &.unknown {
    background-color: $color-label--orange;
  }
  &.not-avail {
    background-color: $color-label--red;
  }
  &.color {
    &--grey {
      background-color: $color-grey-900;
    }
    &--green {
      background-color: $color-label--green;
    }
    &--red {
      background-color: $color-label--red;
    }
    &--yellow {
      background-color: $color-label--orange;
    }
    &--offduty,
    &--off_duty,
    &--notavailable,
    &--not_available {
      background-color: $color-label--red;
    }
    &--onduty,
    &--on_duty {
      background-color: $color-label--green;
    }
    &--unknown {
      background-color: $color-label--orange;
    }
  }
}
$card_floorwarden: "card__floorwarden";
.#{$card_floorwarden} {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  @include display-flex();
  @include flex-wrap(wrap);
  @include align-items(center);
  &-bar {
    width: 14px;
    height: 40px;
    background-color: $color-label--red;
    @include border-radius(2px);
    @include floor-warden-status();
    @media all and (max-width: 400px) {
      height: 36px;
    }
  }
  &-name {
    width: calc(100% - 20px);
    padding-left: 14px;
    font-size: 1.3rem;
    font-weight: 800;
    @media all and (max-width: 400px) {
      font-size: 16px;
    }
  }
  &-left {
    width: calc(100% - 100px);
    @include display-flex();
    @include flex-wrap(wrap);
    @include align-items(center);
  }
  &-right {
    width: 100px;
    @include display-flex();
    @include align-items(center);
    @include justify-content(flex-end);
    @include flex-wrap(wrap);
    .cta {
      width: 100%;
      padding: 4px 0;
      @include display-flex();
      @include align-items(center);
      @include justify-content(flex-end);
    }
    .btn {
      &-message {
        position: relative;
        padding-left: 4px;
        a {
          width: 40px;
          height: 40px;
          background-color: $color-label--blue;
          @include display-flex();
          @include align-items(center);
          @include justify-content(center);
          @include border-radius(6px);
        }
      }
      &-phone {
        position: relative;
        padding-left: 4px;
        a {
          width: 40px;
          height: 40px;
          background-color: $color-label--blue;
          @include display-flex();
          @include align-items(center);
          @include justify-content(center);
          @include border-radius(6px);
        }
      }
    }
  }
}

$card_messengerlist: "card__messengerlist";
.#{$card_messengerlist} {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #ddd;
  padding: 0 5px 15px 5px;
  margin-bottom: 15px;
  @include display-flex();
  @include flex-wrap(wrap);
  @include align-items(center);
  &:last-child {
    border-bottom: unset;
  }
  &-bar {
    width: 14px;
    height: 40px;
    // position: absolute;
    // top: 0;
    // left: 0;
    background-color: $color-label--red;
    @include border-radius(2px);
    @include floor-warden-status();
    @media all and (max-width: 400px) {
      height: 36px;
    }
  }
  &-caption {
    width: calc(100% - 20px);
    padding-left: 10px;
    &-name {
      padding-bottom: 4px;
      font-size: 1.3rem;
      font-weight: 800;
      @media all and (max-width: 400px) {
        font-size: 16px;
      }
    }
    &-role {
      font-size: 1.1rem;
      color: red;
      text-align: left;
      font-weight: normal;
      @media all and (max-width: 400px) {
        font-size: 15px;
      }
    }
    &-areas {
      width: 100%;
      padding-top: 2px;
      display: none;
      p {
        font-size: 14px;
        margin: 0;
        line-height: normal;
      }
    }
  }
  &-left {
    width: calc(100% - 130px);
    @include display-flex();
    @include flex-wrap(wrap);
    @include align-items(center);
    &.w-full {
      width: 100%;
    }
  }
  &-right {
    width: 130px;
    @include display-flex();
    @include align-items(flex-start);
    @include justify-content(flex-end);
    @include flex-wrap(wrap);
    .icon {
      &__unread {
        width: 25px;
        img {
          width: 100%;
          display: block;
          position: relative;
          top: -8px;
        }
      }
    }
    .cta {
      width: 100%;
      padding: 4px 0;
      @include display-flex();
      @include align-items(center);
      @include justify-content(flex-end);
    }
    .btn {
      &-message {
        position: relative;
        padding-left: 4px;
        a {
          width: 40px;
          height: 40px;
          background-color: $color-label--blue;
          @include display-flex();
          @include align-items(center);
          @include justify-content(center);
          @include border-radius(6px);
        }
      }
      &-phone {
        position: relative;
        padding-left: 4px;
        a {
          width: 40px;
          height: 40px;
          background-color: $color-label--blue;
          @include display-flex();
          @include align-items(center);
          @include justify-content(center);
          @include border-radius(6px);
        }
      }
    }
  }
}

$dropdown: "dropdown";
.#{$dropdown} {
  &-status {
    &-label {
      color: white;
      font-weight: 600;
      line-height: normal;
    }
    &-arrowicon {
      position: absolute;
      top: 55%;
      right: 12px;
      @include transform(translateY(-55%));
    }
    &-current {
      width: 100%;
      height: 3rem;
      padding: 5px 10px;
      background-color: white;
      color: white;
      font-weight: 600;
      border: unset;
      position: relative;
      cursor: pointer;
      @include border-radius(4px);
      @include display-flex();
      @include align-items(center);
      @include attr-status-dropdown-variants();
      &:hover {
        cursor: pointer;
      }
    }
    &-modal {
      position: relative;
      @include attr-status-dropdown();
    }
  }
}

$actions: "actions";
.#{$actions} {
  width: 100%;
  &.on-center {
    width: 100%;
    @include display-flex();
    @include justify-content(center);
  }
  &.on-right {
    width: 100%;
    @include display-flex();
    @include justify-content(flex-end);
  }
  .btn {
    &-broadcast {
      width: 125px;
      a {
        width: 100%;
        padding: 5px 5px 5px 8px;
        height: 40px;
        background-color: $color-label--blue;
        @include display-flex();
        @include align-items(center);
        @include justify-content(space-between);
        @include border-radius(6px);
      }
      &-text {
        color: white;
        font-size: 14px;
      }
      &-icon {
        svg {
          width: 34px;
          height: 34px;
          position: relative;
          top: 4px;
        }
      }
    }
  }
}

$not_found: "not-found";
.#{$not_found} {
  width: 100%;
  @include display-flex();
  @include align-items(center);
  @include justify-content(center);
  &-title {
    width: 100%;
    padding: 15px 10px;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    color: $color-grey-200;
  }
}
