.skeleton {
    background: #ced0d0;
    animation: flash 1s infinite;
  }
  
  .skeleton__field {
    position: relative;
    width: 100%;
    height: 40px;
  }
  
  .skeleton__table {
    position: relative;
    width: 100%;
    height: 150px;
  }
  
  .skeleton__table--alternate {
    height: 200px !important;
  }
  
  @keyframes flash {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  