@import url("https://fonts.googleapis.com/css?family=Nunito|Roboto");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@import "__colors.scss";
@import "__skeleton.scss";
@import "__utility.scss";
@import "__mixins.scss";
@import "__button.scss";

body {
  font-size: 16px;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
form,
label,
input,
textarea,
button,
span,
div,
a,
p,
table,
section,
aside,
article {
  margin: 0;
  padding: 0;
  font-family: "Inter", "Nunito", Arial, Helvetica, sans-serif !important;
}

.brand-logo1x {
  width: 100%;
  height: 100%;
  background-image: url("../images/allwardens-logo-white-text.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.brand-logo2x {
  width: 100%;
  height: 100%;
  background-image: url("../images/allwardens-logo-white-text.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.container {
  max-width: 428px;
  width: 100%;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  &.no-px {
    padding-left: 0;
    padding-right: 0;
  }
  &.px {
    &-5 {
      padding-left: 5px;
      padding-right: 5px;
    }
    &-10 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  /*
  &-evacuee-safety-list {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  &-messenger-list {
    margin-top: 10px;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  */
}

/* Overwrite select materialize (default style) */
select {
  width: 100%;
  background-color: white !important;
  padding: 5px 10px;
  border: 1px solid #f2f2f2;
  height: 3rem;
  color: black;
  @include border-radius(4px);

  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='14' fill='%23000000' viewBox='0 0 448 512'%3E%3Cpath d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'/%3E%3C/svg%3E")
    no-repeat;

  background-position: calc(100% - 10px) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 25px !important;
  /* end custom arrow */
}

/* Truncate text to be 2 line */
.cut-off {
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* word-break: break-all; */
  word-break: break-word;
  /* autoprefixer: off */
}

/* Overwrite button materialize */
button {
  &:focus,
  &:active {
    background-color: inherit;
  }
}
