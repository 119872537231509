.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.3);
  }
  
  .spinner {
    position: absolute;
    height: 80px;
    width: 80px;
    animation: rotate 0.8s infinite linear;
    border: 8px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    margin: -40px 0 0 -40px;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .signal {
    border: 5px solid #fff;
    border-radius: 100px;
    height: 100px;
    left: 50%;
    margin: -50px 0 0 -50px;
    opacity: 0;
    position: absolute;
    top: 50%;
    width: 100px;
  
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }
  