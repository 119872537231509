
.message__history {
  position: absolute;
  width: 100%;
  min-height: 70vh;
  max-height: 70vh;
  margin-bottom: 55px !important;
  overflow-x: hidden;
  overflow-y: scroll;
  border-top: 1px solid #7e7e7e;
}

.message__history--nomessage {
  margin-top: 100px;
  font-size: 2rem;
  font-weight: lighter;
  color: #9c9b9b;
}