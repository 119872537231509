@import "../../../../scss/_colors";

.event-management {
  &__list-item {
    padding: 0px 0px 5px 35px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &--has-event-img {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 4px;
      top: 2px;
    }

    &--building-name {
      font-size: 1rem !important;
      color: #000;
      text-transform: capitalize;
    }
  }
}
