.create-event-notice {
  &--warn {
    border: 1px solid #ecc60d;
    border-radius: 6px;
    min-height: 53px;
    display: flex;
    flex-direction: row;
    background-color: #f2e8b2;
  }
  &--error {
    border: 1px solid #eb2a31;
    border-radius: 6px;
    min-height: 53px;
    display: flex;
    flex-direction: row;
    background-color: #f0dedf;
  }

  &__img {
    &-container--warn {
      width: 45px;
      min-height: 53px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 18px;
      background-color: #ecc60d;
    }
    &-container--error {
      width: 45px;
      min-height: 53px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 18px;
      background-color: #eb2a31;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 10px;
  }
}
