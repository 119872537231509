.withdraw {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 425px) {
    &__header {
      margin-bottom: 10px;
    }
  }

  &__title {
    width: 239px;
    font-size: 24px;
    color: #000000;
    font-weight: 700;
  }

  @media screen and (max-width: 425px) {
    &__title {
      font-size: 20px;
    }
  }

  &__form {
    background-color: #f5f5f5;
    padding: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    border-radius: 4px;

    &-label {
      font-size: 12px;
      color: #000000;
    }

    &-info {
      display: flex;
      flex-direction: row;
      column-gap: 8px;
      font-size: 16px;
      color: #333333;

      img {
        width: 22px;
        height: 22px;
      }
    }

    &-reason-input {
      border: none;
      background-color: #ffffff;
      padding: 10px;
      min-height: 58px;
      margin-top: 5px;
      font-size: 12px;
    }

    &-actions {
      position: sticky;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      column-gap: 17px;
      width: 100%;
      margin-top: auto;
      background-color: #ffffff;
      padding: 17px 0px;
    }
  }

  &__back-btn {
    border: 2px solid #333333;
    border-radius: 6px;
    background-color: transparent;
    color: #333333;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 60px;
    padding: 0px;
    font-size: 16px;
    font-weight: 600;
    row-gap: 4px;
    & > img {
      margin: 0px;
    }
  }

  &__primary-btn {
    border: 2px solid #333333;
    border-radius: 6px;
    background-color: transparent;
    color: #333333;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 60px;
    padding: 0px;
    font-size: 16px;
    font-weight: 600;
    row-gap: 4px;
    & > img {
      margin: 0px;
    }
  }
}
