.message__history--cansend {
  position: fixed;
  top: 45vh;
  left: 10vw;
  max-width: 80vw;
  background: rgba(175, 175, 175,0.2);
  box-shadow: 0px 0px 10px rgba(155,155,155,0.5);
  margin-top: 20px;
  padding: 20px;
  border-radius: 20px;
  font-size: 1rem;
}
