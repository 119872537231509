.checkbox-input {
  display: flex;
  align-items: center;
  column-gap: 10px;

  &__container {
    display: flex;
    align-items: center;
  }

  label input {
    display: "none";
  }

  &__checked-img,
  &__empty-checked-img {
    width: 20px;
    height: 20px;
  }
}
