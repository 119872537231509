@import "../scss/_colors";

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: none;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &--small {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
  }

  &--medium {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  &--large {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
  }

  &:disabled {
    background-color: $clr__gray;
    color: $clr__white;
    cursor: not-allowed;
  }

  &-peep {
    width: 50px;
    background-color: $color-label--purple;
    box-shadow: unset;
    border: unset;
    color: white;
    text-transform: uppercase;
    text-align: center;
    padding: 4px 0;
    font-size: 12px;
    font-weight: 700;
    margin-left: 10px;
    cursor: pointer;
    @include border-radius(2px);
    &:hover {
      cursor: pointer;
    }
  }
}
