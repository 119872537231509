@import "../../../../scss/_colors";

.event {
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;

    &-primary-btn {
      box-shadow: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 60px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 6px;
    }
    &-secondary-btn {
      box-shadow: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 60px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 6px;
      border: 2px solid #969bab;
      background-color: #e8e9ed;
      color: #000000;
    }
  }
}

.event__createForm--label {
  font-size: 14px;
  font-weight: bold;
  color: black;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  letter-spacing: 0.8px;
}

.event__selectBuildings {
  border-radius: 5px;
  border: 1px solid #7e7e7e;
  padding: 5px 10px !important;
  margin-top: 5px;
}

.event__selectGroup {
  margin: 5px 0;
}

.event__selectGroupTopItem {
  padding: 5px 5px;
}

.event__selectGroupTopItem--name {
  font-weight: 800;
  color: #000;
  text-transform: capitalize;
}

.event__endButton {
  width: 100%;
  height: 25px;
  border: 1px solid #7e7e7e;
  border-radius: 5px;
  background-color: #eeeeee;
  color: #000;
}

.event__endButton--active,
.event__endButton--active:focus {
  background-color: $clr__red;
  border-color: $clr__red;
  color: #fff;
}

.event__message {
  position: relative;
  top: 20px;
}

.event__message--textarea {
  width: 100%;
  height: 55px;
  background: #fff;
  border: 1px solid #7e7e7e;
  border-radius: 5px;
  outline: none;
  padding: 5px;
  margin-bottom: 10px; /** Extra margin for padding */
}

.event__audience {
  position: relative;
  top: 20px;
  display: block;
  width: 100%;

  img {
    display: inline-block;
    position: relative;
    width: auto;
    height: 20px;
    top: 5px;
  }
}

.event__audience > span {
  font-size: 0.8rem;
  padding: 5px;
  border-radius: 5px;
  text-transform: uppercase;
  margin-left: 10px;
}

.event__audience > span.sms__enabled {
  background-color: $clr__green;
  color: $clr__white;
}

.event__audience > span.sms__disabled {
  background-color: $clr__red;
  $color: $clr__white;
}

.event__audienceButtonGroup {
  position: relative;
  text-align: center;
}

.btn__toggle {
  background: #fff;
  border: 1px solid #e7e7e8;
  border-radius: none;
  color: #333333;
  text-transform: capitalize;
  width: 33%;
  height: 48px;
  padding: 0px !important;
}

.btn__toggle:nth-child(2) {
  border-left: 0;
  border-right: 0;
}

.btn__toggle--active,
.btn__toggle--active:focus {
  background: #454545;
  color: #fff;
}

.btn__toggle:disabled {
  border: 1px solid #7e7e7e;
}

.event__initiateButton {
  position: relative;
  width: 100%;
  height: 25px;
  border: 1px solid #7e7e7e;
  border-radius: 5px;
  background-color: #eeeeee;
  color: #000;
}

.btn__initiateButton--active,
.btn__initiateButton--active:focus {
  background-color: $clr__green;
  border-color: $clr__green;
  color: #fff;
}

/**
 * Event Popup
 */

.eventPopup__overlay {
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999999;
}

.eventPopup__overlay > .eventPopup__content {
  position: relative;
  width: 65%;
  min-height: 15%;
  height: auto;
  background: #fff;
  border-radius: 25px;
  padding: 5%;
  margin: 0 auto;
  top: 25%;
  text-align: center;
}

.eventPopup__content > .eventPopup__content--message {
  position: relative;
  width: 100%;
  min-height: 15%;
  max-height: 75%;
  height: auto;
  font-size: 1.5rem;
  font-weight: 800;
  overflow: hidden;
  text-overflow: ellipsis;
}

.eventPopup__content > .eventPopup__action {
  position: relative;
  width: 100%;
  height: 50px;
  margin-top: 20px;
}

.eventPopup__action > button {
  position: relative;
  width: 50%;
  height: 100%;
  font-size: 1.3rem;
  font-weight: 800;
  background-color: $clr__green;
  color: #fff;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
}

/**
Create Event Notice
*/

.CEN__container {
  position: relative;
  width: 100%;
  min-height: 85px;
  clear: both;
  background: #e5e5e5;
  overflow: hidden;
  margin-top: 20px;
}

.CEN__container > div {
  padding: 2%;
}

.CEN__type {
  position: absolute;
  background-color: $clr__red;
  width: 20%;
  height: 100%;
  float: left;
  left: 0;
  top: 0;
  font-size: 4rem;
  text-align: center;
  color: #fff;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  line-height: 5rem;
}

.CEN__message {
  position: relative;
  background: #e5e5e5;
  width: 80%;
  height: 100%;
  float: right;
  right: 0;
  top: 0;
}
