.login-page {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 30px 10px;

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    max-width: 428px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 15px;
  margin-bottom: 60px;

  &--text {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #545454;
  }
}

@media screen and (max-width: 385px) {
  .login-page {
    padding: 30px 12px 10px;
  }

  .header {
    img {
      height: 40px;
    }

    &--text {
      font-size: 16px;
    }
  }
}

.or {
  margin: 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page-footer {
  color: #969bab;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
