@import "../../../scss/_colors";
@import "../../../scss/_mixins";

.report__actions {
  &--button {
    text-transform: none !important;
    margin: 0px;
    padding: 0px;
    width: 100%;
    border-radius: 10px;
  }
  &--buttonText {
    font-size: 0.9rem;
    position: absolute;
    display: inline-block;
    font-weight: bold;
    width: 100%;
    height: 40%;
    bottom: 0;
    left: 0;
    line-height: 22px;
  }
  &--buttonIconTop {
    position: absolute;
    top: -10px !important;
    width: 40%;
    height: 80%;
    right: 30%;
    font-size: 2em !important;
  }
}

.report__actions--button:disabled,
.report__actions--button[disabled] {
  border: 1px solid #eeeeee;
}

.report {
  &__wrapper {
    margin-top: 20px;
    background: $color-grey-100;
    padding: 8px 20px 15px 20px;
  }
  &__form {
    width: 100%;
    @include display-flex();
    @include flex-wrap(wrap);
    &--label {
      font-size: 16px;
      color: $color-body;
      display: block;
      padding-bottom: 8px;
    }
    &-row {
      width: 100%;
      margin: 15px 0;
    }
    &-field {
      @include display-flex();
      @include align-items(center);
      .icon {
        min-width: 28px;
        max-width: 28px;
        top: -1px;
        position: relative;
        @include display-flex();
        @include align-items(center);
        &--event {
          position: relative;
          top: 1px;
        }
        &--building {}
        &--role {
          svg {
            position: relative;
            top: 2px;
          }
        }
        &--area {
          svg {
            position: relative;
            left: -3px;
            @include transform(scale(1.1));
          }
        }
      }
      .textbox {
        &--event {
          font-weight: 600;
          color: $color-label--red;
        }
      }
      .collection {
        border: unset;
        margin: 0;
        font-weight: normal;
        color: $color-body;
        &-item {
          background-color: transparent;
          padding: 0;
          font-size: 18px;
          line-height: 1.4;
          &:last-child {
            border-bottom: unset;
          }
        }
      }
    }
    &--domain {
      background-color: $color-grey-900;
      padding: 5px 8px;
      border-radius: 4px;
      color: $clr__white;
      font-size: 12px;
      text-transform: uppercase;
      margin-left: 5px;
    }
    &--textbox {
      border: unset !important;
      height: auto !important;
      margin: unset !important;
      font-size: 18px !important;
    }
  }
}