.broadcast-message-and-alert-to-warden-container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-top: 15px;

  .broadcast-message-container,
  .alert-to-warden-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    row-gap: 15px;

    &__label {
      font-size: 15px;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      column-gap: 5px;

      &-btn {
        width: 75px;
        font-size: 15px;
        height: 31px;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        font-weight: 600;
        border-radius: 6px;
      }
    }
  }
}
