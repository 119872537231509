@import "../../../../scss/_colors";
@import "../../../../scss/_mixins";

.groupmsg {
  &__head {
    width: 100%;
    min-height: 56px;
    max-height: 25vh;
    position: relative;
    background-color: black;
    @include display-flex();
    @include align-items(center);
    &-back {
      width: 50px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      height: 100%;
      padding-left: 5px;
      @include display-flex();
      @include align-items(center);
      &--btn {
        width: 100%;
        height: 100%;
        @include display-flex();
        @include align-items(center);
      }
    }

    &-evacuee {
      width: 100%;
      position: relative;
      padding: 5px 0 5px 50px;
      @include display-flex();
      @include flex-wrap(wrap);
      @include align-items(center);
      .info {
        &-text {
          color: white;
          padding-right: 10px;
        }
        &-bubble {
          display: inline-block;
          width: auto;
          min-width: 43px;
          text-align: center;
          padding: 0 5px;
          font-weight: 700;
          line-height: 22px;
          background: $clr__red;
          color: #fff;
          @include border-radius(4px);
          &.color {
            &--green {
              background-color: $color-label--green;
            }
            &--orange {
              background-color: $color-label--orange;
            }
            &--purple {
              background-color: $color-label--purple;
            }
            &--red {
              background-color: $color-label--red;
            }
            &--blue {
              background-color: $color-label--blue;
            }
            &--black {
              background-color: black;
            }
          }
        }
      }
    }

    &-warden {
      width: 100%;
      padding: 5px 15px 15px 50px;
      @include display-flex();
      @include align-items(flex-end);
      .box {
        &-left {
          width: calc(100% - 40px);
          @include display-flex();
          .count {
            width: 70px;
            @include display-flex();
            @include justify-content(center);
            @include flex-wrap(wrap);
            &-name {
              color: white;
            }
            &-number {
              display: inline-block;
              width: auto;
              min-width: 43px;
              text-align: center;
              padding: 0 5px;
              font-weight: 700;
              line-height: 22px;
              background: $color-label--blue;
              color: white;
              @include border-radius(4px);
            }
          }
        }
        &-right {
          &-phonelink {
            width: 40px;
            height: 40px;
            padding: 8px;
            background-color: $color-label--blue;
            border: none;
            @include border-radius(6px);
            @include display-flex();
            @include align-items(center);
            @include justify-content(center);
            &:hover {
              cursor: pointer;
            }
            img {
              width: 100%;
              display: block;
            }
          }
        }
      }
    }
  }
}

.bottom__marginfix {
  padding-bottom: 90px;
}

.no-padding {
  padding: 0 !important;
}
