@import "../../../../scss/_colors";

.modal {
  max-height: 90%;
  width: 90%;
  max-width: 428px;
}

.modal-confirmation {
  border-radius: 10px;
  height: auto !important;
}

.modal > .modal-content {
  position: relative;
  height: 100%;
  background: $clr__white;
}

.modal-content-confirmation {
  height: auto !important;
}

@media (max-width: 425px) {
  .modal-content-confirmation > .row {
    margin-bottom: 10px;
  }
}

.modal label {
  color: $clr__black;
}

.modal .chip {
  background-color: #333333;
  color: #ffffff;
}

#errorPopup > .modal-content .error {
  position: relative;
  background: $clr__red;
  background-image: url("../images/error1x.png");
  background-position: center 15px;
  background-repeat: no-repeat;
  background-size: initial;

  height: 110px;
  border-radius: 5px;
  color: #f5f5f5;
  margin-bottom: 20px;
}

#errorPopup .login_btn_warning {
  color: $clr__red;
}

#errorPopup > .modal-content .error > p {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 5px;
}

#errorPopup > .modal-content .message {
  height: 50%;
  font-size: 1.1em;
}

#errorPopup > .modal-content .actions {
  margin-top: 30px;
}

#errorPopup > .modal-content .actions > button.full-ok {
  width: 100%;
}

#warningPopup > .modal-content .warning {
  position: relative;
  background: $clr__yellow;
  background-position: center 15px;
  background-repeat: no-repeat;
  background-size: 50px;

  height: 110px;
  border-radius: 5px;
  color: $clr__white;
  margin-bottom: 20px;
}

#warningPopup > .modal-content .cross {
  background-image: url("../images/cross1x.png");
}

#warningPopup > .modal-content .check {
  background-image: url("../images/check1x.png");
}

#warningPopup > .modal-content .offdty {
  background-image: url("../images/offdty1x.png");
}

#warningPopup > .modal-content .warning > p {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: bold;
  padding-bottom: 5px;
  padding-top: 5px;
}

#warningPopup > .modal-content .message {
  height: 50%;
  font-size: 1.2em;
}

#warningPopup > .modal-content .actions {
  margin-top: 30px;
}

#warningPopup > .modal-content .actions > button.full-ok {
  width: 100%;
}

#confirmationPopup > .modal-content {
  padding: 0 !important;
  border-radius: 10px;
}

#confirmationPopup > .modal-content .confirm {
  position: relative;
  background-position: center 15px;
  background-repeat: no-repeat;
  background-size: 50px;

  height: auto;
  border-radius: 10px 10px 0px 0px;
  color: #f5f5f5;
}

.confirm-bg {
  background-color: $clr__green !important;
}

.error-bg {
  background-color: $clr__red !important;
}

#confirmationPopup > .modal-content .confirm > p {
  position: relative;
  top: 15%;
  width: 100%;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: bold;
  padding-bottom: 5px;
  padding-top: 5px;
}

#confirmationPopup .container > .row {
  margin-bottom: 10px;
}

#confirmationPopup .container > .row:first-child {
  margin-top: 10px;
}

#confirmationPopup .container > .row > .col {
  padding: 0;
}

#confirmationPopup .col > label {
  display: block;
  position: relative;
  font-size: 1rem;
}

#confirmationPopup .col > input[type="text"] {
  width: 90%;
  margin: 0;
  font-size: 1rem;
  border: 1px solid #dadada;
  padding: 0px 5%;
  color: $clr__black;
  outline: none;
}

#confirmationPopup .col > textarea {
  min-height: 60px;
  max-height: 60px;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid #dadada;
  color: $clr__black;
  outline: none;
}

#confirmationPopup > .actionGroup {
  height: 50px;
  margin-bottom: 0;
}

#confirmationPopup .secondary-btn {
  box-shadow: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 60px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
  border: 2px solid #969bab;
  background-color: #e8e9ed;
  color: #000000;
}

#confirmationPopup .primary-btn {
  box-shadow: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 60px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
  border: 2px solid #00b050;
  background-color: #00b050;
  color: #ffffff;
}
