@import "../../../../scss/_colors";

.container__wide {
  width: 95%;
}

.site__label {
  display: block;
  font-size: 1.3rem;
  line-height: 2.5rem;
  padding: 5px !important;
  text-align: center;
}

.site__textbox {
  height: 100%;
  padding: 5px !important;
  padding: 10px !important;
  border: 1px solid #000;
  margin: 0px !important;
  font-size: 1.2rem;
  color: #000;
}

.column__indicators {
  font-size: 3.5vw;
  font-weight: bold;
  text-align: center;
  min-height: 35px;
  margin-bottom: 0;
}

.column__indicators > .col {
  padding: 0px;
  min-height: 35px;
}

.indicator__onduty {
  background-color: $clr__green;
  min-height: 25px;
  line-height: 1.8rem;
  margin-top: 5px;
  color: #fff;
}

.indicator__unknown {
  background-color: $clr__yellow;
  min-height: 25px;
  line-height: 1.8rem;
  margin-top: 5px;
}

.indicator__notavailable {
  background-color: $clr__red;
  color: #fff;
  min-height: 25px;
  line-height: 1.8rem;
  margin-top: 5px;
}

.table {
  margin-bottom: 20px;
}

.table > .table__head {
  font-size: 1.2rem;
  color: #000;
  padding: 5px;
  margin: 0px;
}

.head__name {
  font-weight: bold;
  font-size: 1.2rem;
}

.head__time {
  font-size: 1rem;
  text-align: right;
  font-weight: 400;
}

.table__row {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0px;
  background: #eee;
}

.table__row,
.table__total {
  text-align: center;
}

.row__onduty {
  background: $clr__green;
  color: #fff;
}
.row__unknown {
  background: $clr__yellow;
}
.row__notavailable {
  background: $clr__red;
  color: #fff;
}

.row__offduty {
  background-color: $clr__black;
  color: #fff;
}

.table__row > .col:first-child {
  text-align: left;
  font-size: 1.1rem;
}

.table__total {
  background-color: #eee;
}

.table__total > .col {
  padding-top: 5px;
  padding-bottom: 10px;
}

.table__total > .col:not(:first-child) {
  font-weight: bold;
}

.table__total > .col:first-child {
  font-size: 1.2rem;
  font-weight: 800;
  text-align: left;
  text-transform: uppercase;
}

.site__total {
  background-color: #7e7e7e !important;
}

.label {
  padding-top: 0px !important;
}

@media screen and (min-width: 470px) {
  .column__indicators {
    font-size: 1.2rem;
  }
}
