@mixin text_truncated($value) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $value;
  -webkit-box-orient: vertical;
}

@mixin text_truncated_bootstrap($value) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $value;
  -webkit-box-orient: vertical;
}