.report-in {
  &__top-action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 0px 10px 35px;
  }

  @media (max-width: 400px) {
    &__top-action {
      margin: 0px 0px 10px 0px;
    }
  }

  &__top-back-btn {
    border: none;
    border-radius: 50%;
    background-color: transparent;
    :hover {
      background-color: transparent;
      cursor: pointer;
    }
    & > img {
      width: 18px;
      height: 20px;
    }
  }

  &__list-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    padding-bottom: 30px;
  }

  &__card {
    width: 100%;
    height: 100%;
    max-width: 355px;
    border-radius: 7px;

    &-header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      color: #ffffff;
      background-color: #eb2a31;
      text-transform: uppercase;
      font-weight: 600;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 10px 15px 15px;
    }

    &-event-type {
      color: #eb2a31;
      text-align: center;
      font-weight: bold;
      font-size: 18px;
    }

    &-client-site {
      color: #000000;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
    }

    &-content-details {
      color: #565656;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      &-img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #e7e7e8;
      }
    }

    &-actions {
      width: 100%;
      height: 28px;
      position: relative;
      margin-top: 5px;
    }

    &-helper-text {
      color: #333333;
      text-align: center;
      font-size: 14px;
    }

    &-btn {
      position: absolute;
      height: 100%;
      padding: 5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      column-gap: 5px;
      border-radius: 4px;
      border: none;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      text-transform: none;
      transition: background-color 0.3s ease, transform 0.2s ease;
    }
  }
}
