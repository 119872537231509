@import "../../../../scss/_colors";

.report__header {
  height: 5vh;
  min-height: 30px !important;
}

.report__logo {
  width: 20%;
  height: 100%;
  padding-right: 10px;
}

.report__title {
  width: 70%;
  height: 100%;
}

@media (max-width: 768px) {
  .report__logo {
    width: 35%;
  }

  .report__title {
    width: 55%;
  }
}

.report__title > h6 {
  width: 100%;
  font-size: 4.5vw;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  display: block;
  height: auto;
}

.optional__nav {
  position: relative;
  width: 100%;
  height: 5vh;
  background: rgb(49, 49, 49);
}

.optional__nav--backbtn {
  position: relative;
  width: 10%;
  height: 5vh;
}

.optional__nav--backbtn > button.back {
  width: 100%;
  height: 5vh;
  background: none;
  border: 0;
}

.optional__nav--backbtn > button > i.material-icons {
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.optional__nav--otherbuttons {
  position: relative;
  width: 85%;
  height: 5vh;
  text-align: right;
}

.optional__nav--otherbuttons > button {
  width: auto;
  height: 4vh;
  margin-top: 0.5vh;
  margin-left: 10px;
  padding: 0 10px;
  float: right;
  border: none;
}

.optional__nav--otherbuttons > button:first-child {
  margin-right: 10px;
}

.optional__nav--otherbuttons > button.logout {
  background-color: $clr__red;
  color: #fff;
  font-weight: bold;
}

@media screen and (min-width: 600px) {
  .report__title > h6 {
    font-size: 2rem;
  }
}
