@import "../../../../scss/_colors";

.login-using-email-form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  img {
    width: 23px;
    height: 37px;
  }

  &__title {
    font-size: 15px;
    font-weight: 500;
    padding-left: 55px;
    text-align: left;
  }

  &__input {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    height: 100%;
  }

  &__input input {
    color: #333333;
  }

  &__input input::placeholder {
    color: #656567;
  }

  &__forgot-password {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
  }

  &__forgot-password a {
    color: #969bab;
  }

  button:disabled,
  button[disabled] {
    background-color: $clr__gray !important;
    color: $clr__white !important;
    cursor: not-allowed !important;
    height: 39px;
  }

  & > button {
    margin-left: 32px;
    font-size: 18px;
  }

  & > button:hover {
    background-color: #00d15f;
  }
}
