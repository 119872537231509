$clr__green: #00b050; // primary green color
$clr__yellow: #ffc000; // primary yellow color
$clr__red: #fb040c; // primary red color
$clr__blue: #276be2; // primary blue color
$clr__evac: #00023e; // primary color or evacuee safety
$clr__peep: #6666ff; // primary peep color
$clr__msg: #62b1f6; // primary message color
$clr__black: #000; // primary blaak color
$clr__white: #fff; // primary white color
$clr__gray: #969bab; // primary gray color

.green--bg {
  background-color: $clr__green;
  color: $clr__white;
}

.white--text {
  color: $clr__white;
}

.green--text {
  color: $clr__green;
}

.yellow--bg {
  background-color: $clr__yellow;
  color: $clr__black;
}

.red--bg {
  background-color: $clr__red;
  color: $clr__white;
}

.blue--bg {
  background-color: $clr__blue;
}

$color-body: #2c2c2c;
$color-grey-100: #fafafa;
$color-grey-200: #b7b7b7;
$color-grey-900: #969bab;

$color-label--green: #19aa5b;
$color-label--orange: #e39f1a;
$color-label--purple: #8f7dec;
$color-label--red: #d9484e;
$color-label--blue: #4272ed;

$color-alert--red: #eb2a31;
$color-alert--green: #00b050;
$color-alert--orange: #f4cd0c;

$color-active: #e7e7e8;
$color-inactive: #e8e9ed;
