@import "../../../Warden/Onduty/scss/warden-onduty";

.indicator__safe--bs {
  background-color:$clr__green;
  min-height: 25px;
  margin-top: 5px;
  line-height: 1.8rem;
  color: #fff;
}

.indicator__unknown--bs {
  background-color: $clr__yellow;
  min-height: 25px;
  margin-top: 5px;
  line-height: 1.8rem;
}

.indicator__danger--bs {
  background-color: $clr__red;
  min-height: 25px;
  margin-top: 5px;
  line-height: 1.8rem;
  color: #fff;
}

.indicator__total--bs {
  background-color: $clr__black;
  min-height: 25px;
  margin-top: 5px;
  line-height: 1.8rem;
  color: #fff;
}

.row__safe--bs {
  // background-color:$clr__green;
  // color: white;
}
.row__unknown--bs {
  // background-color: $clr__yellow;
}
.row__danger--bs {
  // background-color: $clr__red;
  // color: #fff;
}
.row__total--bs {
  // background-color: $clr__black;
  // color: white;
  font-weight: 800;
}


$chart_height : 2vh;
.chart {
  position: relative;
  width: 100%;
  height: $chart_height;
  clear: both;
  overflow: hidden;
}

.chart__line {
  height: 15px;
  min-height: $chart_height;
  float: left;
}

.chart__line--onduty {
  background-color:$clr__green;
}
.chart__line--unknown {
  background-color: $clr__yellow;
}
.chart__line--danger {
  background-color: $clr__red;
}
