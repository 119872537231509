@import "../../../../../scss/_colors";

.message__to {
  height: 100%;
  padding: 5px;
}

.message__to--info {
  font-size: 0.8rem;
  color: #7e7e7e;
}

.message__to--bubble {
  position: relative;
  border-radius: 0.4rem;
  width: 100%;
  background: $color-label--blue !important;
  min-height: 40px;
  padding: 10px;
  color: white;
  font-size: 1.1rem;
}

.message__to--bubble:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-left-color: $color-label--blue !important;
  border-right: 0;
  margin-top: -7px;
  margin-right: -7px;
}

.message__from {
  position: relative;
  height: 100%;
  padding: 5px;
}

.message__from--info {
  font-size: 0.8rem;
  color: #7e7e7e;
}

.message__from--bubble {
  position: relative;
  border-radius: 0.4em;
  width: 100%;
  background: $color-active !important;
  min-height: 40px;
  padding: 10px;
  font-size: 1.1rem;
  white-space: pre-line;
}

.message__from--bubble:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-right-color: $color-active !important;
  border-left: 0;
  margin-top: -7px;
  margin-left: -7px;
}

.message__from--unread {
  position: absolute;
  right: 20%;
  top: 40%;
}

.message__from--name {
  color: $clr__msg;
}

.message__to--name {
  color: #000;
}

.message__unread {
  text-align: center;
}

.message__unread > img {
  display: inline-block;
  margin-top: 30px;
}
